import axios from "axios";
import { url } from "../url";

// Get Transaction API
export const getTransaction = async (requestData) => {
  try {
    const apiUrl = url.getTransaction;
    const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const response = await axios.post(apiUrl, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Get Transaction API >>> ", error.message);
    return error;
  }
};

// Add Transaction API
export const addTransaction = async (requestData) => {
  try {
    const apiUrl = url.addTransaction;
    const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const response = await axios.post(apiUrl, requestData, {
      headers: {
        "Content-Type": "application/json",
        j_unique_id: requestData["j_unique_id"],
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Add Transaction API >>> ", error.message);
    return error;
  }
};
