import React, { useEffect, useState } from "react";
import "./CategoryDetails.module.css";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  addCategory,
  categoryCount,
  deleteCategory,
  getAllCategory,
  updateCategory,
} from "../../API/Category/CategoryApi";
import { notify } from "../FlashMessage/ToastContainer";
import Swal from "sweetalert2";

export default function CategoryDetails() {
  // React-router navigator
  const navigate = useNavigate();

  // Set user role
  // const user_role = parseInt(localStorage.getItem("user_role"));
  // const [userRole, setUserRole] = useState(user_role);

  // State
  const [categoryData, setCategoryData] = useState([]);
  // const [titleName, setTitleName] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [index, setIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [date, setDate] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  // Modal title name
  const [modalTitleName, setModalTitleName] = useState("");
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  // Set Modal title
  function modalTitle(name) {
    setModalTitleName(name);
  }

  // Set the modal field value
  function modalFieldValue(index) {
    setIndex(index + 1);
    setCategoryName(categoryData[index].category_name);
    setSubCategory(categoryData[index].sub_category_name);
    setSelectedStatus(categoryData[index].status);
    setDate(categoryData[index].date);
  }

  // Delete category API
  const delete_category = async (id) => {
    try {
      const response = await deleteCategory(id);

      await get_category();
      await categoryCount();

      // flash message response
      if (response.success) {
        notify(`${response.data.message}`, "success");
      } else {
        notify(`${response.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete category API : ", error);
      return error;
    }
  };

  // Update category API
  const update_category = async (id) => {
    try {
      const update = {};
      update.category_id = id;
      update.category_name = categoryName;
      update.sub_category_name = subCategory;
      update.status = selectedStatus;

      const response = await updateCategory(update);

      await get_category();
      await categoryCount();

      // flash message response
      if (response.success) {
        notify(`${response.data.message}`, "success");
      } else {
        notify(`${response.data.message}`, "error");
      }
    } catch (error) {
      console.log("Update category API : ", error);
      navigate("/login");
      return error;
    }
  };

  // Add category API
  const add_category = async () => {
    try {
      const response = await addCategory({
        category_name: categoryName,
        sub_category_name: subCategory,
      });

      await get_category();
      await categoryCount();
      notify(`${response.data.message}`, "success");
    } catch (error) {
      console.log("Add category API : ", error);
      navigate("/login");
      return error;
    }
  };

  // Get category API
  const get_category = async () => {
    try {
      const result = await getAllCategory();
      setCategoryData(result.data.data);
      return result.data.data;
    } catch (error) {
      console.log("Get category API : ", error);
      navigate("/login"); // Commented out for simplicity in this response
      return error;
    }
  };

  // On load fetch api
  useEffect(() => {
    const fetchData = async () => {
      await get_category();
    };

    (async () => await fetchData())();
  }, []);

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${categoryData[index].category_name} and ${categoryData[index].sub_category_name}`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setCustomerId callback to get the latest value
        setCategoryId((prevCategoryId) => {
          delete_category(prevCategoryId);
          return prevCategoryId; // Return the updated value
        });
      } else {
        Swal.fire("Cancelled", "Your Customer is safe :)", "error");
      }
    });
    return;
  }

  return (
    <>
      {" "}
      <div className="CustomerDetails">
        <Modal
          size="lg"
          isOpen={addModal}
          toggle={() => setAddModal(!addModal)}
        >
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setAddModal(!addModal)}
          >
            {"Add Category"}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              {/* Category name */}
              <div className="form-group row my-3">
                <label for="Ca" className="col-3 col-form-label">
                  Category Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                    }}
                    value={categoryName}
                    placeholder="Enter Category name.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Sub Category name */}
              <div className="form-group row my-3">
                <label
                  for="inputCustomerEmail"
                  className="col-3 col-form-label"
                >
                  Sub Category
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="subCategory"
                    onChange={(e) => {
                      setSubCategory(e.target.value);
                    }}
                    placeholder="Enter Sub category name.."
                    value={subCategory}
                    required
                  ></Input>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setAddModal(!addModal)}
            >
              Cancle
            </button>
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              disabled={categoryName && subCategory ? false : true}
              onClick={async () => {
                setAddModal(!addModal);
                await add_category();
                setCategoryName("");
                setSubCategory("");
              }}
            >
              Add Category
            </button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setModal(!modal)}
          >
            {modalTitleName}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              {/* Category index */}
              <div className="form-group row my-3">
                <label for="Category Index" className="col-3 col-form-label">
                  Index
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="index"
                    value={index}
                    placeholder="Enter Category Index.."
                    disabled={true}
                    required
                  ></Input>
                </div>
              </div>
              {/* Category name */}
              <div className="form-group row my-3">
                <label for="Category name" className="col-3 col-form-label">
                  Category Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                    }}
                    id="categoryName"
                    placeholder="Enter Category Name.."
                    value={categoryName}
                    required
                  ></Input>
                </div>
              </div>
              {/* Sub category name */}
              <div className="form-group row my-3">
                <label for="Sub Category name" className="col-3 col-form-label">
                  Category Sub Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="subCategory"
                    placeholder="Enter Sub Category Name.."
                    onChange={(e) => {
                      setSubCategory(e.target.value);
                    }}
                    value={subCategory}
                    required
                  ></Input>
                </div>
              </div>
              {/* Category status */}
              <div className="form-group row my-3">
                <label for="status" className="col-3 col-form-label">
                  Status
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="status"
                      value={selectedStatus}
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      required
                    >
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* Created date */}
              <div className="form-group row my-3">
                <label for="created date" className="col-3 col-form-label">
                  Created Date
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="date"
                    placeholder="Customer created date..."
                    value={date}
                    required
                    disabled
                  ></Input>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setModal(!Modal)}
            >
              Close
            </button>
            {modalTitleName === "Modify Category" ? (
              <button
                type="submit"
                className="btn btn-primary col-4"
                variant="primary"
                onClick={() => {
                  update_category(categoryId);
                  setModal(!Modal);
                }}
              >
                Save Changes
              </button>
            ) : null}
          </ModalFooter>
        </Modal>
        <div className="CustomerList">
          <div className="cardHeader">
            <h2>All Category</h2>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <i
                className="bi bi-person-fill-add"
                onClick={() => setAddModal(!addModal)}
              ></i>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Index</td>
                <td>Category</td>
                <td>Sub Category</td>
                <td>Status</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {categoryData &&
                categoryData.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        {item.category_name.charAt(0).toUpperCase() +
                          item.category_name.slice(1)}
                      </td>
                      <td>
                        {item.sub_category_name.charAt(0).toUpperCase() +
                          item.sub_category_name.slice(1)}
                      </td>
                      <td>
                        {item.status !== "active" ? (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            style={{ color: "#FF0000" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-check"
                            style={{ color: "#5fb363" }}
                          />
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square btn"
                          className="editIcon"
                          style={{ marginRight: 10, color: "#e3ff0f" }}
                          onClick={() => {
                            modalTitle("Modify Category");
                            modalFieldValue(index);
                            setCategoryId(item.id);
                            setModal(!modal);
                          }}
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          className="deleteIcon"
                          onClick={() => {
                            setCategoryId(item.id);
                            deleteSwal(index);
                          }}
                          style={{ color: "#f50000" }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
