import React, { useEffect, useState } from "react";
import "../Common/Sidebar/Sidebar.css";
import Navbar from "../Common/Navbar/Navbar";
import Sidebar from "../Common/Sidebar/Sidebar";
import Card from "../Common/Cards/Card";
import PriceList from "../Common/PriceList/PriceList";
import { useSelector } from "react-redux";
import CustomerDetails from "../Common/Customer/latestJeweller";
import { Bar } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJs,
  LinearScale,
  Legend,
  Tooltip,
  BarElement,
} from "chart.js";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import ImageSlider from "../Common/ImageSlider/ImageSlider";
import { baseUrl } from "../API/url";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { Backdrop, CircularProgress } from "@mui/material";

// Register the Category scale globally
ChartJs.register(CategoryScale, LinearScale, Legend, Tooltip, BarElement);

export default function Dashboard() {
  // State
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);
  const [titleName, setTitleName] = useState("");
  const [open, setOpen] = useState(false);
  const [customerAverageData, setCustomerAverageData] = useState({
    labels: [],
    data: [],
  });
  const navigate = useNavigate();
  const userRole = parseInt(localStorage.getItem("user_role"));

  // Chart
  const barChartData = {
    labels: customerAverageData.labels,
    datasets: [
      {
        label: "Customer",
        data: customerAverageData.data,
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Bar color
        borderColor: "rgba(75, 192, 192, 1)", // Border color
        borderWidth: 1, // Border width
      },
    ],
  };
  const options = {
    maintainAspectRatio: true, // Allow chart to adjust height
    responsive: true, // Enable responsiveness
  };

  // Redux state
  const selector = useSelector((state) => state.sidebar);

  // User role
  const user_role = parseInt(localStorage.getItem("user_role"));
  // const j_unique_id = localStorage.getItem("j_unique_id");
  let status = "";
  user_role === 1 ? (status = "Jeweller") : (status = "Customer");

  // call api for the active count and total count of jeweller
  const jeweller_count = async () => {
    try {
      const apiUrl = `${baseUrl}/jeweller/count`;
      const response = await axios.get(`${apiUrl}`);
      setActive(response.data.data.active_jeweller);
      setTotal(response.data.data.total_jeweller);
      if (user_role === 1) {
        setTitleName("jeweller");
      } else {
        setTitleName("customer");
      }
    } catch (error) {
      console.log("Api Error", error);
      navigate("/login");
    }
  };

  // call api for the active count and total count of customer
  const customer_count = async (id) => {
    try {
      const apiUrl = `${baseUrl}/customer/count/${id}`;
      const response = await axios.get(`${apiUrl}`);
      setActive(response.data.data.active_customer);
      setTotal(response.data.data.total);
      if (user_role === 1) {
        setTitleName("jeweller");
      } else {
        setTitleName("customer");
      }
    } catch (error) {
      console.log("Api Error", error);
      navigate("/login");
    }
  };

  // customer average data
  const average_customer = async () => {
    try {
      const requestData = {
        j_unique_id: localStorage.getItem("j_unique_id"),
      };
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        return;
      }
      const apiUrl = `${baseUrl}/customer/average-customer`;
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      setCustomerAverageData({
        labels: response?.data?.data?.data?.month,
        data: response?.data?.data?.data?.total,
      });
    } catch (error) {
      console.log("Api Error", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
      if (!localStorage.getItem("auth_token")) {
        navigate("/login");
      }
      if (user_role !== 1) {
        customer_count(localStorage.getItem("j_unique_id"));
      } else {
        jeweller_count();
      }
      await average_customer();
    };
    (async () => await onLoad())();
  }, []);

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Sidebar />
        <div className={`main ${selector.sidebar}`}>
          <Navbar />
          <Link style={{ textDecoration: "none" }} to={`/${titleName}`}>
            <Card
              number={[total, active]}
              CardName={[
                `Total ${titleName
                  .charAt(0)
                  .toLocaleUpperCase()}${titleName.slice(1)}`,
                `Active ${titleName
                  .charAt(0)
                  .toLocaleUpperCase()}${titleName.slice(1)}`,
              ]}
            />
          </Link>
          {/* For only jeweller */}
          {user_role === 0 ? <PriceList /> : null}
          {user_role === 0 ? <ImageSlider /> : null}
          <Bar style={{ padding: 20 }} data={barChartData} options={options} />
          <CustomerDetails />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
