// import { createStore } from "redux";
// import { SidebarReducer } from "../Reducer/SidebarReducer";
// import { AddJewellerModelReducer } from "../Reducer/AddJewellerReducer";
// import { ActiveTabReducer } from "../Reducer/ActiveTabReducer";
// import { userRoleReducer } from "../Reducer/userRoleReducer";
// import reducers from "../Reducer/reducer";
import { configureStore } from "@reduxjs/toolkit";
import { sidebarToggleSlice } from "./Slices/sidebarToggleSlice";

export const store = configureStore({
  reducer: {
    sidebar: sidebarToggleSlice.reducer,
  },
});
