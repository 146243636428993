import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Common/Sidebar/Sidebar";
import Navbar from "../Common/Navbar/Navbar";
import Card from "../Common/Cards/Card";
import CategoryDetails from "../Common/Category/CategoryDetails";
import { categoryCount } from "../API/Category/CategoryApi";

export default function Category() {
  // state
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);

  // redux state
  const selector = useSelector((state) => state.sidebar);

  // category count API

  // On load fetch api
  useEffect(() => {
    const fetchData = async () => {
      // category count return response
      let response = await categoryCount();
      setTotal(response.data.total_category);
      setActive(response.data.active_category);
      // setCategoryData(await categoryCount());
    };

    fetchData();
  }, [setActive, setTotal]);

  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        <Card
          number={[total, active]}
          CardName={[`Total Category `, `Active Category`]}
        />
        <CategoryDetails />
      </div>
    </div>
  );
}
