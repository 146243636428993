import React from "react";
import Navbar from "../Common/Navbar/Navbar";
import Sidebar from "../Common/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import ProfileDetails from "../Common/Profile/ProfileDetails";

export default function Profile() {
  // Sidebar toggle
  const selector = useSelector((state) => state.sidebar);
  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        {/* Profile content */}
        <ProfileDetails />
      </div>
    </div>
  );
}
