import React, { useState } from "react";
import styles from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "../API/url";

function SetPassword({ params }) {
  console.log("params >>>>> ", params);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Set Password API
  const set_password = async () => {
    try {
      const apiUrl = `${baseUrl}/register`;
      const requestData = {
        new_password: newPassword,
        confirm_password: confirmPassword,
        j_unique_id: localStorage.getItem("j_unique_id"),
      };
      const response = await axios.post(apiUrl, requestData);

      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
        navigate("/login");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/setpassword");
      }
    } catch (error) {
      console.log("Set password API : ", error);
      notify("Some internal server error", "error");
      navigate("/setpassword");
    }
  };
  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              {/* <Image  /> */}
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>Sign Up</h2>
            </div>
            <div className={styles.UserInput} style={{ position: "relative" }}>
              <p>New Password</p>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password..."
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <button
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
                style={{
                  position: "absolute",
                  top: "75%",
                  right: "4%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    showPassword ? "bi-eye-fill" : "bi-eye-slash-fill"
                  }`}
                  style={{
                    fontSize: "1.2em",
                    color: "#555",
                  }}
                ></i>
              </button>
            </div>
            <div className={styles.UserInput} style={{ position: "relative" }}>
              <p>Confirm Password</p>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password..."
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <button
                className="password-toggle-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                aria-label={
                  showConfirmPassword ? "Hide password" : "Show password"
                }
                style={{
                  position: "absolute",
                  top: "75%",
                  right: "4%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    showConfirmPassword ? "bi-eye-fill" : "bi-eye-slash-fill"
                  }`}
                  style={{
                    fontSize: "1.2em",
                    color: "#555",
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className={styles.loginFooter}>
            <div className={styles.LoginButton} style={{ marginTop: "10px" }}>
              {/* <Link to="/SignUpDetails"> */}
              <input
                type="submit"
                onClick={() => {
                  set_password();
                }}
                value={"Set Password"}
              />
              {/* </Link> */}
            </div>
            <div className={styles.loginSignUp}>
              <p>
                Do Have account?
                <Link to="/login" style={{ textDecoration: "none" }}>
                  {"SignIn"}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default SetPassword;
