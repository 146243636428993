import React, { useEffect, useState } from "react";
// import styles from "./CustomerDetails.module.css";
import "./CustomerDetails.module.css";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../FlashMessage/ToastContainer";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { baseUrl } from "../../API/url";

export default function CustomerDetails() {
  const [customerData, setCustomerData] = useState([]);
  const navigate = useNavigate();
  const [titleName, setTitleName] = useState("");
  const user_role = parseInt(localStorage.getItem("user_role"));
  const userRole = user_role;
  // const [updateIndex, setUpdateIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  // Modal title name
  const [modalTitleName, setModalTitleName] = useState("");

  //Form Model Validation
  const [customerName, setCustomerName] = useState(null);
  const [customerCardId, setCustomerCardId] = useState(null);
  const [customerBalance, setcustomerBalance] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [address, setAddress] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [customerId, setCustomerId] = useState(null);
  const [cardId, setCardId] = useState("");
  const [tempId, setTempId] = useState("");
  const [jewellerId, setJewellerId] = useState(
    `${localStorage.getItem("j_unique_id")}`
  );

  const selector = useSelector((state) => state.sidebar);

  // call api for the customer data
  const customer_data = async (j_unique_id) => {
    try {
      const requestData = {};
      requestData.j_unique_id = j_unique_id;
      // requestData.c_unique_id = customerId;
      const apiUrl = `${baseUrl}/customer/getcustomer`;
      const response = await axios.post(apiUrl, requestData);
      setCustomerData(response.data.data.data);
      if (userRole === 1) {
        setTitleName("Jeweller");
      } else {
        setTitleName("Customer");
      }
    } catch (error) {
      console.log("Api Error", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Add customer API
  const add_customer = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const requestData = {
        j_unique_id: jewellerId,
        email_address: customerEmail,
        card_id: cardId,
      };
      const apiUrl = `${baseUrl}/customer/addcustomer`;
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
          j_unique_id: jewellerId,
        },
      });
      setCustomerData(response.data.data.data);
      // set title name
      if (userRole === 1) {
        setTitleName("Jeweller");
      } else {
        setTitleName("Customer");
      }
      // toast message
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
      // fetch the latest data
      if (userRole === 1) {
        await customer_data(null);
      } else {
        await customer_data(localStorage.getItem("j_unique_id"));
      }
      navigate("/customer");
    } catch (error) {
      console.log("Add customer API : ", error);
      notify("Some internal server error", "error");
    }
  };

  // Update customer API
  const update_customer = async (id) => {
    try {
      const apiUrl = `${baseUrl}/customer/updatecustomer`;
      const requestData = {};
      requestData.name = customerName;
      requestData.mobile_number = mobileNumber;
      requestData.zipcode = zipcode;
      requestData.address = address;
      requestData.card_id = customerCardId;
      requestData.balance = customerBalance;
      requestData.status = selectedStatus;
      requestData.id = id;

      const response = await axios.put(apiUrl, requestData);

      // fetch latest changes
      setCustomerId(null);
      if (userRole !== 1) {
        await customer_data(localStorage.getItem("j_unique_id"));
      } else {
        await customer_data(null);
      }

      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Update customer API : ", error);
      navigate("/login");
    }
  };

  // Delete customer API
  const delete_customer = async (id) => {
    try {
      const apiUrl = `${baseUrl}/customer/delete/${id}`;
      const response = await axios.delete(apiUrl);

      // fetch latest changes
      setCustomerId(null);
      if (userRole !== 1) {
        await customer_data(localStorage.getItem("j_unique_id"));
      } else {
        await customer_data(null);
      }

      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete customer API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      if (userRole !== 1) {
        customer_data(localStorage.getItem("j_unique_id"));
      } else {
        customer_data(selector?.jeweller_id);
      }
    };
    (async () => await onLoad())();
  }, []);

  // Set the modal field value
  function modalFieldValue(index) {
    setCustomerName(customerData[index].name);
    setCustomerCardId(customerData[index].card_id);
    setcustomerBalance(customerData[index].balance);
    setSelectedStatus(customerData[index].status);
    setCustomerEmail(customerData[index].email_address);
    setMobileNumber(customerData[index].mobile_number);
    setStartDate(customerData[index].start_date);
    setDueDate(customerData[index].due_date);
    setAddress(customerData[index].address);
    setZipcode(customerData[index].zipcode);
  }

  // Form Validation
  // function ValidationFormModel() {
  // jewellerData[index].name = JewellersName;
  //   if (customerName.length === 0) {
  //     Swal.fire({
  //       title: "Failed",
  //       text: "Please Enter Name!!!",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //       width: "400px",
  //       confirmButtonColor: "0E3386",
  //       color: "red",
  //       position: "top",
  //       showClass: {
  //         popup: "animate__animated animate__fadeInDown",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__fadeOutUp",
  //       },
  //     });
  //     return;
  //   }
  //   setModal(!Modal);
  // }

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${customerData[index].name}`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setCustomerId callback to get the latest value
        setCustomerId((prevCustomerId) => {
          delete_customer(prevCustomerId);
          return prevCustomerId; // Return the updated value
        });
      } else {
        Swal.fire("Cancelled", "Your Customer is safe :)", "error");
      }
    });
    return;
  }

  function handleCardId(e) {
    let id = e.target.value;
    console.log(id);
  }

  const [debouncedCardId, setDebouncedCardId] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCardId(cardId);
    }, 300); // Delay time in milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [cardId]);

  useEffect(() => {
    if (debouncedCardId) {
      console.log("Final Card ID: ", debouncedCardId);
      // Perform your logic to check and replace the card ID here
    }
  }, [debouncedCardId]);

  const handleCardIdChange = (e) => {
    const newCardId = e.target.value;
    setCardId(newCardId);
  };

  // Set Modal title
  function modalTitle(name) {
    setModalTitleName(name);
  }

  return (
    <>
      {" "}
      <div className="CustomerDetails">
        <Modal
          size="lg"
          isOpen={addModal}
          toggle={() => setAddModal(!addModal)}
        >
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setAddModal(!addModal)}
          >
            {"Add Customer"}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              <div className="form-group row my-3">
                <label for="JewellerId" className="col-3 col-form-label">
                  Jeweller ID
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="jewellerId"
                    onChange={(e) => {
                      setJewellerId(e.target.value);
                    }}
                    value={jewellerId}
                    placeholder="Enter Jeweller Id.."
                    disabled={userRole === 1 ? false : true}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputCustomerEmail"
                  className="col-3 col-form-label"
                >
                  Customer Email
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                    }}
                    placeholder="Enter Customer Email.."
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="inputCardId" className="col-3 col-form-label">
                  Card Id
                </label>
                <div className="col-7">
                  <input
                    type="text"
                    className="form-control"
                    id="cardid"
                    value={cardId}
                    onChange={handleCardIdChange}
                    placeholder="Enter Card Id.."
                    required
                  />
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      setCardId("");
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setAddModal(!addModal)}
            >
              Cancle
            </button>
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                add_customer();
                setAddModal(!addModal);
              }}
            >
              Send Mail
            </button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setModal(!modal)}
          >
            {modalTitleName}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              {userRole === 1 ? (
                <div className="form-group row my-3">
                  <label for="JewellerId" className="col-3 col-form-label">
                    Jeweller ID
                  </label>
                  <div className="col-9">
                    <Input
                      type="text"
                      className="form-control"
                      id="jewellerId"
                      onChange={(e) => {
                        setJewellerId(e.target.value);
                      }}
                      value={jewellerId}
                      placeholder="Enter Jeweller Id.."
                      disabled={userRole === 1 ? false : true}
                      required
                    ></Input>
                  </div>
                </div>
              ) : null}
              <div className="form-group row my-3">
                <label for="inputCustomerName" className="col-3 col-form-label">
                  Customer Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setCustomerName(e.target.value);
                    }}
                    id="name"
                    placeholder="Enter Customer Name.."
                    value={customerName}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerMobileNo"
                  className="col-3 col-form-label"
                >
                  Customer Mobile No
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Enter Customer MobileNo.."
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                    value={mobileNumber}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerEmail"
                  className="col-3 col-form-label"
                >
                  Customer Email
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="email_address"
                    onChange={(e) => {
                      setCustomerEmail(e.target.value);
                    }}
                    placeholder="Enter Customer Email.."
                    value={customerEmail}
                    disabled={true}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerGSTNo"
                  className="col-3 col-form-label"
                >
                  Customer CardId
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setCustomerCardId(e.target.value);
                    }}
                    placeholder="Enter customer CardId.."
                    value={customerCardId}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerZipcode"
                  className="col-3 col-form-label"
                >
                  Customer Balance
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setcustomerBalance(e.target.value);
                    }}
                    placeholder="Enter customer balance.."
                    value={customerBalance}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="status" className="col-3 col-form-label">
                  Status
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="status"
                      value={selectedStatus}
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      required
                    >
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="inputJewellerUPI" className="col-3 col-form-label">
                  Start Date
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Customer start date..."
                    // onChange={(e) => setDate(e.target.value)}
                    value={startDate}
                    required
                    disabled
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="inputJewellerUPI" className="col-3 col-form-label">
                  Due Date
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Customer due date..."
                    // onChange={(e) => setDate(e.target.value)}
                    value={dueDate}
                    required
                    disabled
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerGSTNo"
                  className="col-3 col-form-label"
                >
                  Customer Zipcode
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    onChange={(e) => {
                      setZipcode(e.target.value);
                    }}
                    placeholder="Enter customer zipcode.."
                    value={zipcode}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputJewellerGSTNo"
                  className="col-3 col-form-label"
                >
                  Customer Address
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    placeholder="Enter customer address.."
                    value={address}
                    required
                  ></Input>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setModal(!Modal)}
            >
              Close
            </button>
            {modalTitleName === "Modify Customer" ? (
              <button
                type="submit"
                className="btn btn-primary col-4"
                variant="primary"
                onClick={() => {
                  update_customer(customerId);
                  customer_data(localStorage.getItem("j_unique_id"));
                  setModal(!Modal);
                }}
              >
                Save Changes
              </button>
            ) : null}
          </ModalFooter>
        </Modal>
        <div className="CustomerList">
          <div className="cardHeader">
            <h2>Customer</h2>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <i
                className="bi bi-person-fill-add"
                onClick={() => setAddModal(!addModal)}
              ></i>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Customer Name</td>
                <td>Card_id</td>
                <td>Balance</td>
                <td>Status</td>
                <td>Action</td>
                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              {customerData &&
                customerData.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.card_id}</td>
                      <td>{item.balance}</td>
                      <td>
                        {item.status !== "active" ? (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            style={{ color: "#FF0000" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-check"
                            style={{ color: "#5fb363" }}
                          />
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square btn"
                          className="editIcon"
                          style={{ marginRight: 10, color: "#e3ff0f" }}
                          onClick={() => {
                            modalTitle("Modify Customer");
                            modalFieldValue(index);
                            setCustomerId(item.id);
                            setModal(!modal);
                          }}
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          className="deleteIcon"
                          onClick={() => {
                            setCustomerId(item.id);
                            deleteSwal(index);
                          }}
                          style={{ color: "#f50000" }}
                        />
                      </td>
                      <td>
                        <i
                          className="bi bi-arrow-right-circle detailIcon"
                          onClick={() => {
                            modalTitle("Customer Details");
                            modalFieldValue(index);
                            setModal(!modal);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
