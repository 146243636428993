// authetication token
export const authToken = localStorage.getItem("auth_token")?.trim();

export const tokenVerification = async () => {
  if (!authToken) {
    console.error("Authentication token is missing.");
    return;
  }
};

// logged in USER unique id
export const j_unique_id = localStorage.getItem("j_unique_id");
