import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import { useNavigate } from "react-router-dom";
import { notify } from "../Common/FlashMessage/ToastContainer";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "../API/url";

function ForgotPassword() {
  const [jewellerId, setJewellerId] = useState("");
  const navigate = useNavigate(); // Get the history object

  // verify otp API
  const forgot_password = async () => {
    try {
      const apiUrl = `${baseUrl}/forgotpassword`;
      const requestData = {
        j_unique_id: jewellerId,
      };
      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // toast notify
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
        localStorage.setItem("j_unique_id", jewellerId);
        navigate("/verifyotp");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/forgotpassword");
      }
    } catch (error) {
      console.log("Forgot Password API : ", error);
      notify("Some internal server", "error");
    }
  };

  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>Forgot Password</h2>
            </div>
            <div className={styles.UserInput}>
              <p>Jeweller Id</p>
              <input
                type="text"
                placeholder="Enter Jeweller Id..."
                onChange={(e) => {
                  setJewellerId(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.loginFooter}>
            <div className={styles.LoginButton}>
              <input
                type="submit"
                value={"Verify Id"}
                onClick={() => {
                  forgot_password();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ForgotPassword;
