import axios from "axios";
import {
  authToken,
  j_unique_id,
} from "../../Constant/Constant";
import { url } from "../url";

// delete image api
export const deleteImage = async (requestData) => {
  try {
    const response = await axios.delete(`${url.deleteImage}/${requestData}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
        j_unique_id: j_unique_id,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Delete Image API >>> ", error.message);
    return error;
  }
};

// get image api
export const getImage = async (requestData) => {
  try {
    const response = await axios.post(url.getImage, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
        j_unique_id: j_unique_id,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Get Image API >>> ", error.message);
    return error;
  }
};

// add image api
export const addImage = async (requestData) => {
  try {
    const response = await axios.post(url.addImage, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${authToken.slice(1, -1)}`,
        j_unique_id: j_unique_id,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Add Image API >>> ", error.message);
    return error;
  }
};

// Update image api
export const updateImage = async (requestData) => {
  try {
    const response = await axios.post(url.updateImage, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${authToken.slice(1, -1)}`,
        j_unique_id: j_unique_id,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Update Image API >>> ", error.message);
    return error;
  }
};
