import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../Common/FlashMessage/ToastContainer";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { jeweller_id } from "../app/Slices/sidebarToggleSlice";
import { baseUrl } from "../API/url";

function Login() {
  // set the redux state
  const dispatch = useDispatch((state) => state.sidebar);

  const [jUniqueId, setJUniqueId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Get the history object

  const loginApi = async (j_unique_id, password) => {
    try {
      const apiUrl = `${baseUrl}/login`;
      // request body data
      const requestData = {
        j_unique_id: j_unique_id,
        password: password,
      };

      // Make the POST request
      const response = await axios.post(apiUrl, requestData);

      // Close the modal or perform other actions on successful response
      // setAddModal(false);
      if (response.data.success) {
        // Show success
        localStorage.setItem(
          "auth_token",
          JSON.stringify(response.data.data.data.auth_token)
        );
        localStorage.setItem(
          "user_role",
          JSON.stringify(response.data.data.data.user_role)
        );
        localStorage.setItem(
          "j_unique_id",
          response.data.data.data.j_unique_id
        );
        // set the jeweller id in redux state for super admin role
        if (JSON.stringify(response.data.data.data.user_role) !== "1") {
          dispatch(jeweller_id(response.data.data.data.j_unique_id));
        }
        notify("Login Successfully", "success");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setPassword(null);
        // show error
        notify(`${response.data.data.message}`, "error");
        navigate("/login");
      }
    } catch (error) {
      // Handle errors
      console.log("Error sending mail:", error);

      // Show error
      notify("Failed to send mail. Please try again.", "error");
    }
  };

  // Set logout api
  const logout = async (id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/logout`;
      const requestData = {
        j_unique_id: localStorage.getItem("j_unique_id"),
      };

      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      if (response.data.success) {
        dispatch(jeweller_id(null));
        localStorage.removeItem("auth_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("j_unique_id");
        // notify(`logout successfull`, "success");
        navigate("/login");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/login");
      }
    } catch (error) {
      console.log("Logout API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // when render login page remove all data from localstorage
  // useEffect(() => {
  //   const removeData = async () => {
  //     await logout();
  //   };
  //   (async () => await removeData())();
  // }, []);

  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>LOGIN</h2>
            </div>
            <div className={styles.UserInput}>
              <p>UserID</p>
              <input
                type="text"
                placeholder="Enter UserID..."
                onChange={(e) => {
                  setJUniqueId(e.target.value);
                }}
              />
            </div>
            <div className={styles.UserInput} style={{ position: "relative" }}>
              <p>Password</p>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password..."
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
                style={{
                  position: "absolute",
                  top: "75%",
                  right: "4%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    showPassword ? "bi-eye-fill" : "bi-eye-slash-fill"
                  }`}
                  style={{
                    fontSize: "1.2em",
                    color: "#555",
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className={styles.loginFooter}>
            <div className={styles.forgotPassword}>
              <Link to="/forgotpassword" className={styles.forgot} style={{ textDecoration: "none" }}>
                {"forgot password?"}
              </Link>
            </div>
            <div className={styles.LoginButton}>
              {/* <Link to="/"> */}
              <input
                type="submit"
                value={"Login"}
                onClick={() => {
                  loginApi(jUniqueId, password);
                }}
              />
              {/* </Link> */}
            </div>
            <div className={styles.loginSignUp}>
              <p>
                Don’t Have account?{" "}
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  {"SignUp"}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
