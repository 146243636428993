import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Common/Sidebar/Sidebar";
import Navbar from "../Common/Navbar/Navbar";
import Card from "../Common/Cards/Card";
import JewellerDetails from "../Common/Jeweller/JewellerDetails";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../API/url";

export default function Jeweller() {
  const selector = useSelector((state) => state.sidebar);
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);
  const [titleName, setTitleName] = useState(null);
  const navigate = useNavigate();

  // call api for the active count and total count of jeweller
  const jeweller_count = async () => {
    try {
      const apiUrl = `${baseUrl}/jeweller/count`;
      const response = await axios.get(`${apiUrl}`);
      setActive(response.data.data.active_jeweller);
      setTotal(response.data.data.total_jeweller);
      setTitleName("Jeweller");
    } catch (error) {
      console.log("Api Error", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      jeweller_count();
    };
    (async () => await onLoad())();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        <Card
          number={[total, active]}
          CardName={[`Total ${titleName}`, `Active ${titleName}`]}
        />
        <JewellerDetails />
      </div>
    </div>
  );
}
