import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Sidebar.css";
import "../../index";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { notify } from "../FlashMessage/ToastContainer";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { jeweller_id } from "../../app/Slices/sidebarToggleSlice";
import { baseUrl } from "../../API/url";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

function Sidebar() {
  // dispatch the redux state
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);

  // Set logout api
  const logout = async (id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/logout`;
      const requestData = {
        j_unique_id: localStorage.getItem("j_unique_id"),
      };

      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      if (response.data.success) {
        dispatch(jeweller_id(null));
        localStorage.removeItem("auth_token");
        localStorage.removeItem("user_role");
        localStorage.removeItem("j_unique_id");
        notify(`logout successfull`, "success");
        navigate("/login");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/login");
      }
    } catch (error) {
      console.log("Logout API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  useEffect(() => {
    setUserRole(parseInt(localStorage.getItem("user_role")));
  }, []);

  // Sidebar sidebarSelector for the Toggle Sidebar
  const selector = useSelector((state) => state.sidebar);
  const jewellerId = selector?.jeweller_id;

  return (
    <>
      <div>
        <div className={`sidebar ${selector.sidebar}`} id="sidebar">
          <ul>
            <li>
              <Link to="/">
                <img
                  className="icon"
                  src={require("../../assests/logo.png")}
                  alt="Logo"
                ></img>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    className="fs-5"
                    style={{
                      letterSpacing: "2px",
                      fontWeight: "900",
                    }}
                  >
                    RETRONIC
                  </span>
                  <span
                    className="fs-0 fw-light"
                    style={{ letterSpacing: 0, fontSize: "16px" }}
                  >
                    TECHNOLOGIES
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <NavLink to="/">
                <i className="bi bi-speedometer2 me-2 fs-4 icon"></i>
                <span className="fs-5 fw-bold Sidebartext">Dashboard</span>
              </NavLink>
            </li>
            {userRole === 1 && (
              <li>
                <NavLink to="/jeweller">
                  <i className="bi bi-shop fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Jewellers</span>
                </NavLink>
              </li>
            )}
            {userRole === 1 ? (
              jewellerId && (
                <li>
                  <NavLink to="/customer">
                    <i className="bi bi-people fs-4 me-2 icon"></i>
                    <span className="fs-5 Sidebartext">Customers</span>
                  </NavLink>
                </li>
              )
            ) : (
              <li>
                <NavLink to="/customer">
                  <i className="bi bi-people fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Customers</span>
                </NavLink>
              </li>
            )}
            {userRole === 1 ? (
              jewellerId && (
                <li>
                  <NavLink to="/product">
                    <i className="bi bi-table fs-4 me-2 icon"></i>
                    <span className="fs-5 Sidebartext">Products</span>
                  </NavLink>
                </li>
              )
            ) : (
              <li>
                <NavLink to="/product">
                  <i className="bi bi-table fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Products</span>
                </NavLink>
              </li>
            )}
            {userRole === 1 ? (
              jewellerId && (
                <li>
                  <NavLink to="/transaction">
                    {/* <AccountBalanceWalletIcon /> */}
                    <i className="bi bi-wallet2 fs-4 me-2 icon"></i>
                    <span className="fs-5 Sidebartext">Transaction</span>
                  </NavLink>
                </li>
              )
            ) : (
              <li>
                <NavLink to="/transaction">
                  {/* <AccountBalanceWalletIcon /> */}
                  <i className="bi bi-wallet2 fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Transaction</span>
                </NavLink>
              </li>
            )}
            {userRole === 1 ? (
              jewellerId && (
                <li>
                  <NavLink to="/installment">
                    <i className="bi bi-wallet2 fs-4 me-2 icon"></i>
                    <span className="fs-5 Sidebartext">Installment</span>
                  </NavLink>
                </li>
              )
            ) : (
              <li>
                <NavLink to="/installment">
                  <i className="bi bi-wallet2 fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Installment</span>
                </NavLink>
              </li>
            )}
            {userRole === 1 && (
              <li>
                <NavLink to="/category">
                  <i className="bi bi-filter-square fs-4 me-2 icon"></i>
                  <span className="fs-5 Sidebartext">Category</span>
                </NavLink>
              </li>
            )}
            <li
              onClick={() => {
                logout();
              }}
            >
              <NavLink to="/login">
                <i className="bi bi-box-arrow-right fs-4 me-2 icon"></i>
                <span className="fs-5 Sidebartext">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Sidebar;
