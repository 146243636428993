import axios from "axios";
import { url } from "../url";

// add price api
export const addPrice = async (requestData) => {
  try {
    const response = await axios.post(`${url.addPrice}`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("Add Price API >>> ", error.message);
    return error;
  }
};

// get price api
export const getPrice = async () => {
  try {
    const response = await axios.get(url.getPrice, {
      headers: {
        "Content-Type": "application/json",
        j_unique_id: localStorage.getItem("j_unique_id"),
      },
    });

    return response.data;
  } catch (error) {
    console.log("Get Price API >>> ", error.message);
    return error;
  }
};

// update price api
export const updatePrice = async (requestData) => {
  try {
    const response = await axios.put(url.updatePrice, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("Update Price API >>> ", error);
    return error;
  }
};

// delete price api
export const deletePrice = async (id) => {
  try {
    const response = await axios.delete(`${url.deletePrice}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.log("Delete Price API >>> ", error);
    return error;
  }
};
