import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Common/Sidebar/Sidebar";
import Navbar from "../Common/Navbar/Navbar";
import Card from "../Common/Cards/Card";
import CustomerDetails from "../Common/Customers/CustomerDetails";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../API/url";

export default function Customer() {
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);
  const [titleName, setTitleName] = useState(null);
  const navigate = useNavigate();
  const user_role = parseInt(localStorage.getItem("user_role"));
  const selector = useSelector((state) => state.sidebar);

  // call api for the active count and total count of customer
  const customer_count = async (id) => {
    try {
      const apiUrl = `${baseUrl}/customer/count/${id}`;
      const response = await axios.get(`${apiUrl}`);
      setActive(response.data.data.active_customer);
      setTotal(response.data.data.total);
      setTitleName("Customer");
    } catch (error) {
      console.log("Api Error", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      if (!localStorage.getItem("auth_token")) {
        navigate("/login");
      }
      if (user_role === 1) {
        await customer_count("0");
      } else {
        await customer_count(localStorage.getItem("j_unique_id"));
      }
    };

    (async () => await onLoad())();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        <Card
          number={[total, active]}
          CardName={[`Total ${titleName}`, `Active ${titleName}`]}
        />
        <CustomerDetails />
      </div>
    </div>
  );
}
