import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Common/Sidebar/Sidebar";
import Navbar from "../Common/Navbar/Navbar";
import Card from "../Common/Cards/Card";
import ProductsDetails from "../Common/Products/ProductsDetails";
import axios from "axios";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../API/url";

export default function Product() {
  const selector = useSelector((state) => state.sidebar);
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    // Api for active and total product count
    const product_count = async () => {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      try {
        // const apiUrl = `${baseUrl}/product/count`;
        const apiUrl = `${baseUrl}/product/count`;
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authToken.slice(1, -1)}`,
            j_unique_id:
              JSON.stringify(localStorage.getItem("user_role")) === "1"
                ? selector?.jeweller_id
                : localStorage.getItem("j_unique_id"),
          },
        });
        setActive(response.data.data.active_product);
        setTotal(response.data.data.total);
      } catch (error) {
        console.log("get product_count Api error ", error);
        notify("Some internal server error", "error");
        navigate("/login");
      }
    };

    (async () => await product_count())();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        <Card
          number={[total, active]}
          CardName={["Total Product", "Active Product"]}
        />
        <ProductsDetails />
      </div>
    </div>
  );
}
