import React, { useEffect, useRef, useState } from "react";
import "./ProductsDetails.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import { ModalBody } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { notify } from "../FlashMessage/ToastContainer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addImage, deleteImage, updateImage } from "../../API/Image/imageApi";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { baseUrl } from "../../API/url";

// Add the icon to the library for global use
library.add(faCircleCheck, faCircleXmark);
library.add(faPenToSquare, faPenToSquare);
library.add(faTrash, faTrash);

function ProductsDetails() {
  // redux state data
  const selector = useSelector((state) => state.sidebar);

  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);

  const fileInputRef = useRef(null);

  // const handleIconClick = () => {
  //   fileInputRef.current.click();
  // };
  // const [updateIndex, setUpdateIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  //Form Model Validation
  const [productName, setProductName] = useState(null);
  const [carat, setCarat] = useState(null);
  const [weight, setWeight] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const [date, setDate] = useState(null);
  const [productColor, setProductColor] = useState(null);
  const [productId, setProductId] = useState();
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [detail, setDetail] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [image, setImage] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [path, setPath] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  // Modal title name
  const [modalTitleName, setModalTitleName] = useState("");

  useEffect(() => {
    const onLoad = async () => {
      await product_data();
      await category_data();
    };
    (async () => await onLoad())();
  }, []);

  useEffect(() => {
    // check the user_role
    if (JSON.stringify(localStorage.getItem("user_role")) === "1") {
      setPath(selector?.jeweller_id);
    } else {
      setPath(localStorage.getItem("j_unique_id"));
    }
  }, []);

  // Get Product API
  const product_data = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/getproduct`;
      const requestData = {};
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
          j_unique_id:
            JSON.stringify(localStorage.getItem("user_role")) === "1"
              ? selector?.jeweller_id
              : localStorage.getItem("j_unique_id"),
        },
      });
      setProductData(response.data.data.data);
    } catch (error) {
      console.log("Get product API : ", error);
      notify("some internal server error", "error");
    }
  };

  // Get Sub Category API
  const sub_category_data = async (selected_category) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }

      const apiUrl = `${baseUrl}/getsubcategory`;
      const requestData = {
        category_name: selected_category,
      };
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      setSubCategory(response.data.data.data);
    } catch (error) {
      console.log("Get product API : ", error);
      notify("some internal server error", "error");
    }
  };

  // Get Category API
  const category_data = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/getcategory`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      setCategory(response.data.data.data);
      setCategoryName(response.data.data.data[0].category_name);
      // fetch sub category data
      await sub_category_data(response.data.data.data[0].category_name);
    } catch (error) {
      console.log("Get product API : ", error);
      notify("some internal server error", "error");
    }
  };

  // Fetch sub category base on category name
  // if (categoryName) {
  //   sub_category_data();
  //   console.log("Category length",categoryName.length);
  // }

  // Delete product API
  const delete_product = async (current_product_id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/deleteproduct/${current_product_id}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
          j_unique_id: localStorage.getItem("j_unique_id"),
        },
      });
      // for latest data
      await product_data();
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete product API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Modal field value
  function modalFieldValue(index) {
    // index = index - 1;
    setProductName(
      productData[index].name.charAt(0).toUpperCase() +
        productData[index].name.slice(1)
    );
    setCarat(productData[index].carat);
    setWeight(productData[index].weight);
    setProductPrice(productData[index].price);
    setDate(productData[index].date);
    setProductColor(productData[index].color);
    setSelectedStatus(productData[index].status);
    setDetail(productData[index].details);
    setImage(productData[index].images);
  }

  // set Image modal field value
  function imageModalFieldValue(index) {
    setImageId(image[index].id);
    setImageName(image[index].image_name);
  }

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${
        isImage ? "remove image" : productData[index]?.name
      }`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setProductId callback to get the latest value
        setProductId((prevProductId) => {
          if (isImage) {
            delete_image(prevProductId);
            return prevProductId;
          } else {
            delete_product(prevProductId);
            return prevProductId; // Return the updated value
          }
        });
      } else {
        Swal.fire("Cancelled", "Your item is safe :)", "error");
      }
    });
    return;
  }

  // check file is less then 300 kb or not
  async function checkFileSize(files) {
    const areFilesValid = Array.from(files).every(
      (file) => file.size <= 300 * 1024
    );
    if (!areFilesValid) {
      setSelectedFiles([]);
      alert("Please select a file less than 300kb in size");
      return false;
    }
    return true;
  }

  // Set Modal title
  function modalTitle(name) {
    setModalTitleName(name);
  }

  // call api for add slider image
  const add_image = async (files) => {
    try {
      const formData = new FormData();
      formData.append("image_type", 2);
      formData.append("product_id", productId);
      Array.from(files).forEach((file) => {
        formData.append("images", file);
      });
      const response = await addImage(formData);

      // set flash message
      if (response?.success) {
        setModal(!Modal);
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }
      // fetch latest change
      await product_data();
    } catch (error) {
      console.log("Add Image API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Update Image API
  const update_image = async (files) => {
    try {
      const formData = new FormData();
      formData.append("image_type", 2);
      formData.append("id", imageId);
      formData.append("image_name", imageName);
      formData.append("product_id", productId);
      Array.from(files).forEach((file) => {
        formData.append("images", file);
      });
      const response = await updateImage(formData);
      // set flash message
      if (response?.success) {
        setModal(!Modal);
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }
      // fetch latest change
      await product_data();
    } catch (error) {
      console.log("Update Image API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Update product API
  const update_product = async (id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }

      const apiUrl = `${baseUrl}/updateproduct`;
      const requestData = {};
      requestData.name = productName;
      requestData.color = productColor;
      requestData.price = productPrice;
      requestData.carat = carat;
      requestData.weight = weight;
      requestData.date = date;
      requestData.status = selectedStatus;
      requestData.details = detail;
      requestData.category_name = categoryName;
      requestData.sub_category_name = subCategoryName;
      requestData.id = id;

      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      // for fetch latest data
      await product_data();

      if (response.data.success) {
        notify("Product updated successfully", "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Update product API : ", error);
      notify("Some internal server error", "error");
    }
  };

  // Add Product API
  const add_product = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      const j_unique_id =
        JSON.stringify(localStorage.getItem("user_role")) === "1"
          ? selector?.jeweller_id
          : localStorage.getItem("j_unique_id");
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }

      const apiUrl = `${baseUrl}/addproduct`;
      const formData = new FormData();
      formData.append("name", productName);
      formData.append("color", productColor);
      formData.append("price", productPrice);
      formData.append("carat", carat);
      formData.append("weight", weight);
      formData.append("date", date);
      formData.append("status", selectedStatus);
      formData.append("details", detail);
      formData.append("category_name", categoryName);
      formData.append("sub_category_name", "chain");
      // Append all files to the FormData object
      selectedFiles.forEach((file) => {
        formData.append("images", file);
      });

      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${authToken.slice(1, -1)}`,
          j_unique_id: j_unique_id,
        },
      });

      // for fetching latest data
      await product_data();

      if (response.data.success) {
        notify("Product Added successfully", "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Add product API : ", error);
      notify("Some internal server error", "error");
    }
  };

  // Delete Image API
  const delete_image = async (id) => {
    try {
      let response = await deleteImage(id);
      // refresh the page
      window.location.reload();
      // close the modal
      setModal(!modal);
      // fetch the latest changes
      await product_data();
      if (response.success) {
        notify("Image deleted successfully", "success");
      } else {
        notify(`${response.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete Image API : ", error);
      notify("Some internal server error", "error");
    }
  };

  return (
    <>
      <div className="CustomerDetails">
        <Modal
          size="lg"
          isOpen={addModal}
          toggle={() => setAddModal(!addModal)}
        >
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setAddModal(!addModal)}
          >
            {"Add Product"}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              {/* Product name */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Product Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                    placeholder="Enter Product Name.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Product weight */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Product Weight
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                    placeholder="Enter Product Weight.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Product carat */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Product Carat
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setCarat(e.target.value);
                    }}
                    placeholder="Enter Product carat.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Product color */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inpurProductColor"
                  className="col-3 col-form-label"
                >
                  Product Color
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setProductColor(e.target.value);
                    }}
                    placeholder="Enter Product color.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Product price */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Product Price
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setProductPrice(e.target.value);
                    }}
                    placeholder="Enter Product Price..."
                    required
                  ></Input>
                </div>
              </div>
              {/* Category */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Category Name
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="#"
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                        sub_category_data(e.target.value);
                      }}
                      required
                    >
                      {/* Provide some values for the dropdown options */}
                      {category.map((item, index) => {
                        return (
                          <option key={index} value={item.category_name}>
                            {item.category_name.charAt(0).toUpperCase() +
                              item.category_name.slice(1)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* Sub category name */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Sub Category Name
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="#"
                      onChange={(e) => {
                        setSubCategoryName(e.target.value);
                      }}
                      disabled={false}
                      value={subCategoryName}
                      required
                    >
                      {/* Provide some values for the dropdown options */}
                      {subCategory.map((item, index) => {
                        return (
                          <option
                            key={index}
                            id={index}
                            value={item.sub_category}
                          >
                            {item.sub_category.charAt(0).toUpperCase() +
                              item.sub_category.slice(1)}
                          </option>
                        );
                      })}
                    </select>
                    {/* <div className="custom-select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div> */}
                  </div>
                </div>
              </div>
              {/* Product Details */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputCustomerEmail"
                  className="col-3 col-form-label"
                >
                  Product Detail
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                    placeholder="Enter Product details.."
                    required
                  ></Input>
                </div>
              </div>
              {/* Image File */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputImageFile"
                  className="col-3 col-form-label"
                >
                  Image Files
                </label>
                <div className="col-9">
                  <Input
                    type="file"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      const files = e.target.files;
                      const areFilesValid = Array.from(files).every(
                        (file) => file.size <= 300 * 1024
                      );
                      setDisabled(!areFilesValid);
                      setSelectedFiles([...files]);
                    }}
                    placeholder="Please select image file"
                    multiple={true}
                    required
                  />
                  <span style={{ color: "red", float: "right" }}>
                    Max: size 300kb
                  </span>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setAddModal(!addModal)}
            >
              Cancle
            </button>
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              disabled={disabled}
              onClick={() => {
                add_product();
                setAddModal(!addModal);
              }}
            >
              Add Product
            </button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setModal(!modal)}
          >
            {modalTitleName}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              {/* <div className="form-group row my-3">
              <label htmlFor="StaticID" className="col-3 col-form-label ">
                Product ID
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  readonly
                  className="AddJewellerInput form-control-plaintext"
                  id="#"
                  value={"1"}
                  required
                  disabled
                  style={{ paddingLeft: 13 }}
                />
              </div>
            </div> */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerName"
                  className="col-3 col-form-label"
                >
                  Product Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                    value={productName}
                    className="form-control"
                    id="#"
                    placeholder="Enter Product Name.."
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerMobileNo"
                  className="col-3 col-form-label"
                >
                  Carat
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    onChange={(e) => {
                      setCarat(e.target.value);
                    }}
                    placeholder="Enter Product carat..."
                    value={carat}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerEmail"
                  className="col-3 col-form-label"
                >
                  Product Weight
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                    placeholder="Enter Jeweller Email.."
                    value={weight}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label htmlFor="ProductPrice" className="col-3 col-form-label">
                  Product Price
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    placeholder="Enter Product Price..."
                    onChange={(e) => {
                      setProductPrice(e.target.value);
                    }}
                    value={productPrice}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label htmlFor="ProductPrice" className="col-3 col-form-label">
                  Product Color
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    placeholder="Enter Product Color..."
                    onChange={(e) => {
                      setProductColor(e.target.value);
                    }}
                    value={productColor}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label htmlFor="inputStatus" className="col-3 col-form-label">
                  {`Product Status`}
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="statusDropdown" // Corrected id attribute
                      required
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      value={selectedStatus}
                    >
                      {/* Provide some values for the dropdown options */}
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerUPI"
                  className="col-3 col-form-label"
                >
                  Details
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Enter Product details..."
                    onChange={(e) => setDetail(e.target.value)}
                    value={detail}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerUPI"
                  className="col-3 col-form-label"
                >
                  Date
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Enter Product date..."
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                    disabled
                  ></Input>
                </div>
              </div>
              {/* <div className="form-group row my-3">
              <label htmlFor="inputJewellerUPI" className="col-3 col-form-label">
                Jeweller UPI-Scanner
              </label>
              <div className="custom-file col-9">
                <Input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  required
                />
              </div>
            </div> */}
              <div className={`container-lg bg-light border border-1 m-0`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">Images</p>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      const files = e.target.files;
                      const isValid = await checkFileSize(files);
                      if (isValid) {
                        setSelectedFiles(Array.from(files));
                        if (isUpdate) {
                          await update_image(files);
                        } else {
                          await add_image(files);
                        }
                      }
                    }}
                    placeholder="Please select image file"
                    multiple={!isUpdate}
                    required
                  />
                  {isImage && image.length < 9 ? (
                    <i
                      className="bi bi-file-earmark-plus-fill file-add"
                      onClick={() => {
                        setIsUpdate(false);
                        document.getElementById("fileInput").click();
                      }}
                    ></i>
                  ) : null}
                </div>
                <div className="row border my-0 d-flex justify-content-evenly align-items-center">
                  {image.map((item, index) => {
                    return (
                      <div
                        className="col-sm-12 col-lg-6 col-xl-3 border m-0 p-0" // Added hover-zoom-container class
                        style={{ overflow: "hidden" }}
                        key={index}
                      >
                        <img
                          // src={require(`../../../src/Images/${localStorage.getItem(
                          //   "j_unique_id"
                          // )}/product/${item.product_id}/${item.image_name}`)}
                          src={`https://golddata.in:5800/images/${path}/product/${item.product_id}/${item.image_name}`}
                          alt="Product"
                          className="hover-zoom"
                        />
                        <div className="justify-content-center align-items-center d-flex p-1">
                          <Input
                            type="file"
                            ref={fileInputRef}
                            className="form-control"
                            id="#"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              const files = e.target.files;
                              const areFilesValid = Array.from(files).every(
                                (file) => file.size <= 300 * 1024
                              );
                              setDisabled(!areFilesValid);
                              setSelectedFiles([...files]);
                            }}
                            placeholder="Please select image file"
                            multiple={true}
                            required
                          />
                          {isImage && (
                            <FontAwesomeIcon
                              icon="fa-solid fa-pen-to-square btn"
                              className="editIcon"
                              style={{ marginRight: 10, color: "#e3ff0f" }}
                              onClick={() => {
                                setIsUpdate(true);
                                imageModalFieldValue(index);
                                document.getElementById("fileInput").click();
                              }}
                            />
                          )}
                          {isImage && (
                            <FontAwesomeIcon
                              icon="fa-solid fa-trash"
                              className="deleteIcon"
                              style={{ color: "#f50000" }}
                              onClick={() => {
                                setProductId(item.id);
                                setIsImage(true);
                                deleteSwal(index);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* {isImage && image.length > 0 ? (
                  <div className="d-flex justify-content-center align-items-center p-1">
                    <button
                      type="submit"
                      className="btn btn-md bg-info"
                      disabled={true}
                    >
                      Change File
                    </button>
                  </div>
                ) : null} */}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setModal(!Modal)}
            >
              Close
            </button>
            {modalTitleName === "Modify Product" ? (
              <button
                type="submit"
                className="btn btn-primary col-4"
                variant="primary"
                onClick={() => {
                  update_product(productId);
                  product_data();
                  setModal(!Modal);
                }}
              >
                Save Changes
              </button>
            ) : null}
          </ModalFooter>
        </Modal>
        {/* Jeweller Details */}
        <div className="CustomerList">
          <div className="cardHeader">
            <h2>Products</h2>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <i
                className="bi bi-person-fill-add"
                onClick={() => {
                  setAddModal(!addModal);
                }}
              ></i>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Product Name</td>
                <td>Carat</td>
                <td>Weight</td>
                <td>Product Price</td>
                <td>Status</td>
                <td>Action</td>
                <td>Date</td>
                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              {productData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                    </td>
                    <td>{item.carat}</td>
                    <td>{item.weight}</td>
                    <td>{item.price}</td>
                    <td>
                      {item.status !== "active" ? (
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          style={{ color: "#FF0000" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          style={{ color: "#5fb363" }}
                        />
                      )}
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square btn"
                        className="editIcon"
                        style={{ marginRight: 10, color: "#e3ff0f" }}
                        onClick={() => {
                          modalTitle("Modify Product");
                          modalFieldValue(index);
                          setProductId(item.id);
                          setIsImage(true);
                          setModal(!modal);
                        }}
                      />
                      <FontAwesomeIcon
                        icon="fa-solid fa-trash"
                        className="deleteIcon"
                        style={{ color: "#f50000" }}
                        onClick={() => {
                          // setUpdateIndex(index);
                          setProductId(item.id);
                          deleteSwal(index);
                        }}
                      />
                    </td>
                    <td>{item.date}</td>
                    <td>
                      <i
                        className="bi bi-arrow-right-circle detailIcon"
                        onClick={() => {
                          modalTitle("Customer Details");
                          modalFieldValue(index);
                          setProductId(item.id);
                          setIsImage(false);
                          setModal(!modal);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ProductsDetails;
