import React, { useState } from "react";
import styles from "./VerifyOtp.module.css";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../Common/FlashMessage/ToastContainer";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "../API/url";

function VerifyOtp() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(null);

  // verify otp API
  const verify_otp = async () => {
    try {
      const apiUrl = `${baseUrl}/verifyotp`;
      const requestData = {
        otp: otp,
      };
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          j_unique_id: localStorage.getItem("j_unique_id"),
        },
      });

      // toast notify
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
        navigate("/setpassword");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/verifyotp");
      }
    } catch (error) {
      console.log("Verify otp API : ", error);
      notify("Some internal server", "error");
    }
  };

  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              {/* <Image  /> */}
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>VERIFY OTP</h2>
            </div>
            <div className={styles.UserInput}>
              <p>Enter OTP</p>
              <input
                type="text"
                pattern="\d*"
                maxLength={6}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, "");
                  setOtp(parseInt(inputValue, 10));
                }}
                placeholder="Enter OTP..."
              />
            </div>
          </div>
          <div className={styles.loginFooter}>
            <div className={styles.LoginButton}>
              {/* <Link to="/setpassword"> */}
              <input
                type="submit"
                onClick={() => {
                  verify_otp();
                }}
                value={"VerifyOTP"}
              />
              {/* </Link> */}
            </div>
            <div className={styles.loginSignUp}>
              <p>
                Don’t Have account?{" "}
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  {"SignUp"}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default VerifyOtp;
