import React from "react";
import "./Card.css";

function Card(props) {
  return (
    <div className="cardBox col-lg-12 col-xxl-12 col-md-12">
      {/* card 1 */}
      <div className="card1">
        <div>
          <div className="numbers">{props.number[0]}</div>
          <div className="CardName">{props.CardName[0]}</div>
        </div>
        <div className="iconBox">
          <i className="bi bi-person-plus"></i>
        </div>
      </div>
      {/* card 2 */}
      <div className="card1">
        <div>
          <div className="numbers">{props.number[1]}</div>
          <div className="CardName">{props.CardName[1]}</div>
        </div>
        <div className="iconBox">
          <i className="bi bi-person-check-fill"></i>
        </div>
      </div>
    </div>
  );
}

export default Card;
