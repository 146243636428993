import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Screens/Dashboard";
import Login from "./Screens/Login";
import SignUp from "./Screens/SignUp";
import SetPassword from "./Screens/SetPassword";
import SignUpDetails from "./Screens/SignUpDetails";
import Jeweller from "./Screens/Jeweller";
import Product from "./Screens/Product";
import Profile from "./Screens/Profile";
import Customer from "./Screens/Customer";
import VerifyOtp from "./Screens/VerifyOtp";
import Category from "./Screens/Category";
import Transaction from "./Screens/Transaction";
import Installment from "./Screens/Installment";
import ForgotPassword from "./Screens/ForgotPassword";
import PrivacyAndPolicy from "./Screens/PrivacyAndPolicy";

function App() {
  let userRole = 1;
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        {/* <Initial Page> */}
        <Route exact path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="verifyotp" element={<VerifyOtp />} />
        <Route path="setpassword" element={<SetPassword />} />
        <Route path="signupdetails" element={<SignUpDetails />} />
        {/* </ -- Initial Page> */}
        {/* <Main Page> */}
        {/* <Super Admin Page> */}
        {userRole === 1 && <Route path="jeweller" element={<Jeweller />} />}
        {userRole === 1 && <Route path="category" element={<Category />} />}
        {/* </ -- Super Admin Page> */}
        <Route path="product" element={<Product />} />
        <Route path="transaction" element={<Transaction />} />
        <Route path="installment" element={<Installment />} />
        <Route path="customer" element={<Customer />} />
        <Route path="profile" element={<Profile />} />
        {/* </ -- Main Page> */}
        <Route
          path="privacy-policy-mobile-app"
          exact
          element={<PrivacyAndPolicy />}
        />
        {/* <404 Page Not Found> */}
        <Route path="*" element={<Login />} />
        {/* </ -- 404 Page Not Found> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
