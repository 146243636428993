import React, { useCallback, useEffect, useState } from "react";
import styles from "./InstallmentDetails.module.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notify } from "../FlashMessage/ToastContainer";
import {
  addInstallment,
  getInstallment,
} from "../../API/Installment/InstallmentApi";
import { useSelector } from "react-redux";

const InstallmentDetails = () => {
  // redux state
  const selector = useSelector((state) => state.sidebar);
  // React router dom
  const navigate = useNavigate();

  // state
  const [installmentData, setInstallmentData] = useState([]);
  const [index, setIndex] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [cardId, setCardId] = useState("");
  const [remark, setRemark] = useState("");
  const [credit, setCredit] = useState(null);
  const [amount, setAmount] = useState(null);
  const [debit, setDebit] = useState("");
  const [total, setTotal] = useState("");
  const [selectedAmountType, setSelectedAmountType] = useState("credit");

  // state for modal
  const [addModal, setAddModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitleName, setModalTitleName] = useState("");

  // Set modal title name
  function modalTitle(title) {
    setModalTitleName(title);
  }

  // Set modal field value
  function modalFieldValue(index) {
    setIndex(index + 1);
    setCustomerId(installmentData[index].c_unique_id);
    setCardId(installmentData[index].card_id);
    setRemark(installmentData[index].remark);
    setCredit(installmentData[index].credit);
    setDebit(installmentData[index].debit);
    setTotal(installmentData[index].total);
  }

  // Get Trancsaction API
  const get_installment = useCallback(async () => {
    try {
      const response = await getInstallment({
        j_unique_id:
          JSON.stringify(localStorage.getItem("user_role")) === "1"
            ? selector?.jeweller_id
            : localStorage.getItem("j_unique_id"),
      });
      setInstallmentData(response.data.data);
    } catch (error) {
      console.log("Get Installment API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  }, [navigate]);
  // Card Reader
  // const card_reader = async () => {
  //   try {
  //     const response = await getReadCard();

  //     if (response?.success) {
  //       setCardId(response.data.id);
  //     }
  //   } catch (error) {
  //     console.log("Get Card Reader API : ", error);
  //     notify("Some internal server error", "error");
  //     return error;
  //   }
  // };

  // Add Installment API
  const add_installment = async () => {
    try {
      let data = {
        c_unique_id: customerId,
        transaction_type: selectedAmountType,
        credit: null,
        debit: null,
        card_id: cardId,
        remark: remark,
      };

      if (selectedAmountType === "credit") {
        data.credit = amount;
      } else {
        data.debit = amount;
      }

      data.j_unique_id =
        JSON.stringify(localStorage.getItem("user_role")) === "1"
          ? selector?.jeweller_id
          : localStorage.getItem("j_unique_id");

      const response = await addInstallment(data);

      console.log("response >>>> ", response);

      if (response.success) {
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }

      // fetch latest changes
      await get_installment();

      setAmount(null);
      setCustomerId(null);
      setSelectedAmountType("credit");
    } catch (error) {
      console.log("Add Installment API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  };

  useEffect(() => {
    get_installment();
  }, [get_installment]);

  // Delete Swal
  // function deleteSwal(index) {
  //   Swal.fire({
  // title: "Failed",
  // text: `Are you sure ? ${transactionData[index].name}`,
  // icon: "warning",
  // type: "warning",
  // showCancelButton: true,
  // confirmButtonColor: "#0E3386",
  // confirmButtonText: "Yes",
  // cancelButtonText: "No, cancel please!",
  // closeOnConfirm: false,
  // closeOnCancel: false,
  // width: "400px",
  // confirmButtonColor: "0E3386",
  //   color: "red",
  //   position: "top",
  //   showClass: {
  //     popup: "animate__animated animate__fadeInDown",
  //   },
  //   hideClass: {
  //     popup: "animate__animated animate__fadeOutUp",
  //   },
  // }).then((result) => {
  //   if (result.isConfirmed) {
  // Use setPriceId callback to get the latest value
  //       setTransactionId((prevPriceId) => {
  //         return prevPriceId; // Return the updated value
  //       });
  //     } else {
  //       Swal.fire("Cancelled", "Your Price is safe :)", "error");
  //     }
  //   });
  //   return;
  // }

  // on reload fetch the price list data
  useEffect(() => {
    const fetchData = async () => {
      await get_installment();
    };
    fetchData();
  }, [get_installment]);

  return (
    <div className={styles.container}>
      {/* Add Price Modal */}
      <Modal size="lg" isOpen={addModal} toggle={() => setAddModal(!addModal)}>
        <ModalHeader
          className="AddInstallmentHeader"
          toggle={() => setAddModal(!addModal)}
        >
          {"Add Installment"}
        </ModalHeader>
        <ModalBody className="AddInstallmentBody">
          <form>
            {/* Customer Id */}
            {/* <div className="form-group row my-3">
              <label for="CustomerId" className="col-3 col-form-label">
                Customer Id
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="customerId"
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                  }}
                  placeholder="Enter Customer Id.."
                  required
                ></Input>
              </div>
            </div> */}
            {/* Card Id */}
            <div className="form-group row my-3">
              <label for="CardId" className="col-3 col-form-label">
                Card Id
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="cardId"
                  onChange={(e) => {
                    setCardId(e.target.value);
                  }}
                  placeholder="Enter Card Id .."
                  value={cardId}
                  required
                ></Input>
              </div>
              {/* <div className="col-3">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    card_reader();
                  }}
                >
                  Card Id
                </button>
              </div> */}
            </div>
            {/* Remark */}
            <div className="form-group row my-3">
              <label for="Remark" className="col-3 col-form-label">
                Remark
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="remark"
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  placeholder="Enter remark .."
                  value={remark}
                  required
                ></Input>
              </div>
            </div>
            {/* Amount Type */}
            <div className="form-group row my-3">
              <label for="amountType" className="col-3 col-form-label">
                {`Amount Type`}
              </label>
              <div className="col-9">
                <div className="custom-select-wrapper">
                  <select
                    className="form-control custom-select"
                    id="amountTypeDropdown" // Corrected id attribute
                    required
                    onChange={(e) => {
                      setSelectedAmountType(e.target.value);
                    }}
                    value={selectedAmountType}
                  >
                    {/* Provide some values for the dropdown options */}
                    <option value="credit">Credit</option>
                    <option value="debit">Debit</option>
                  </select>
                </div>
              </div>
            </div>
            {/* Amount */}
            <div className="form-group row my-3">
              <label for="amount" className="col-3 col-form-label">
                Amount
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="amount"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  placeholder="Enter Amount .."
                  required
                ></Input>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary col-4"
            variant="secondary"
            onClick={() => {
              setCardId(null);
              setRemark(null);
              setAddModal(!addModal);
            }}
          >
            Cancle
          </button>
          <button
            type="submit"
            className="btn btn-primary col-4"
            variant="primary"
            disabled={false}
            onClick={() => {
              setCardId(null);
              setRemark(null);
              add_installment();
              setAddModal(!addModal);
            }}
          >
            Add Installment
          </button>
        </ModalFooter>
      </Modal>
      {/* Modify installment modal */}
      <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader
          className="AddInstallmentHeader"
          toggle={() => setModal(!modal)}
        >
          {modalTitleName}
        </ModalHeader>
        <ModalBody className="AddInstallmentBody">
          <form>
            {/* Installment index */}
            <div className="form-group row my-3">
              <label for="installmentIndex" className="col-3 col-form-label">
                Index
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="index"
                  onChange={(e) => {
                    setIndex(e.target.value);
                  }}
                  value={index}
                  placeholder="Enter Installment Index.."
                  disabled={true}
                  required
                ></Input>
              </div>
            </div>
            {/* Customer Id */}
            <div className="form-group row my-3">
              <label for="customerId" className="col-3 col-form-label">
                Customer Id
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                  }}
                  id="customerId"
                  placeholder="Enter Customer Id.."
                  value={customerId}
                  required
                ></Input>
              </div>
            </div>
            {/* Card Id */}
            <div className="form-group row my-3">
              <label for="cardId" className="col-3 col-form-label">
                Card Id
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="price"
                  placeholder="Enter Card Id.."
                  onChange={(e) => {
                    setCardId(e.target.value);
                  }}
                  value={cardId}
                  required
                ></Input>
              </div>
            </div>
            {/* Remark */}
            <div className="form-group row my-3">
              <label for="remark" className="col-3 col-form-label">
                Card Id
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="price"
                  placeholder="Enter remark.."
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  value={remark}
                  required
                ></Input>
              </div>
            </div>
            {/* Debit */}
            <div className="form-group row my-3">
              <label for="debit" className="col-3 col-form-label">
                Debit
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="debit"
                  placeholder="Enter Debit.."
                  onChange={(e) => {
                    setDebit(e.target.value);
                  }}
                  value={debit}
                  required
                ></Input>
              </div>
            </div>
            {/* Credit */}
            <div className="form-group row my-3">
              <label for="credit" className="col-3 col-form-label">
                Credit
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="credit"
                  placeholder="Enter Credit.."
                  onChange={(e) => {
                    setCredit(e.target.value);
                  }}
                  value={credit}
                  required
                ></Input>
              </div>
            </div>
            {/* Total */}
            <div className="form-group row my-3">
              <label for="total" className="col-3 col-form-label">
                Total
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="total"
                  placeholder="Enter Total.."
                  onChange={(e) => {
                    setTotal(e.target.value);
                  }}
                  value={total}
                  required
                ></Input>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary col-4"
            variant="secondary"
            onClick={() => setModal(!Modal)}
          >
            Close
          </button>
          {modalTitleName === "Modify Details" ? (
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                setModal(!Modal);
              }}
            >
              Save Changes
            </button>
          ) : null}
        </ModalFooter>
      </Modal>
      {/* Table view */}
      <div className={styles.pricelist}>
        <div className={styles.cardheader}>
          <h2>Installment List</h2>
          <div
            className={styles.cardheaderrightview}
            style={{ justifyContent: "flex-end" }}
          >
            <i
              className="bi bi-person-fill-add"
              onClick={() => {
                setAddModal(!addModal);
              }}
            ></i>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td>Index</td>
              <td>Customer id</td>
              <td>Card id</td>
              <td>Remark</td>
              <td>Debit</td>
              <td>Credit</td>
              <td>Total</td>
              {/* <td>Action</td> */}
              <td>Details</td>
            </tr>
          </thead>
          <tbody>
            {installmentData &&
              installmentData.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td style={{ maxWidth: 100, textAlign: "justify" }}>
                      {item.c_unique_id}
                    </td>
                    <td>{item.card_id}</td>
                    <td>{item.remark}</td>
                    <td>{parseFloat(item.debit).toFixed(2)}</td>
                    <td>{parseFloat(item.credit).toFixed(2)}</td>
                    <td>{parseFloat(item.total).toFixed(2)}</td>
                    {/* <td> */}
                    {/* <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square btn"
                        className="editIcon"
                        style={{ marginRight: 10, color: "#e3ff0f" }}
                        onClick={() => {
                          modalFieldValue(index);
                          modalTitle("Modify Details");
                          setTransactionId(item.id);
                          setModal(!modal);
                        }}
                      /> */}
                    {/* <FontAwesomeIcon
                        icon="fa-solid fa-trash"
                        className="deleteIcon"
                        onClick={() => {
                          setTransactionId(item.id);
                          deleteSwal(index);
                        }}
                        style={{ color: "#f50000" }}
                      /> */}
                    {/* </td> */}
                    <td>
                      <i
                        className="bi bi-arrow-right-circle detailIcon"
                        onClick={() => {
                          modalFieldValue(index);
                          modalTitle("Details");
                          setModal(!modal);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InstallmentDetails;
