import React, { useEffect, useState } from "react";
import styles from "./PriceList.module.css";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notify } from "../FlashMessage/ToastContainer";
import {
  addPrice,
  deletePrice,
  getPrice,
  updatePrice,
} from "../../API/Price/PriceApi";
import { j_unique_id } from "../../Constant/Constant";
import { getCategory } from "../../API/Category/CategoryApi";

const PriceList = () => {
  // React router dom
  const navigate = useNavigate();

  // state
  const [priceListData, setPriceListData] = useState([]);
  const [index, setIndex] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [priceId, setPriceId] = useState();
  const [categoryName, setCategoryName] = useState("Gold");
  const [category, setCategory] = useState([]);

  // state for modal
  const [addModal, setAddModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitleName, setModalTitleName] = useState("");

  // Set modal title name
  function modalTitle(title) {
    setModalTitleName(title);
  }

  // Set modal field value
  function modalFieldValue(index) {
    setIndex(index + 1);
    setName(priceListData[index].name);
    setPrice(priceListData[index].price);
  }

  // Get Category
  const get_category = async () => {
    try {
      let response = await getCategory();
      setCategory(response.data.data);
    } catch (error) {
      console.log("Get category API : ", error);
      notify("some internal server error", "error");
    }
  };

  useEffect(() => {
    get_category();
  }, []);

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${priceListData[index].name}`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setPriceId callback to get the latest value
        setPriceId((prevPriceId) => {
          delete_price(prevPriceId);
          return prevPriceId; // Return the updated value
        });
      } else {
        Swal.fire("Cancelled", "Your Price is safe :)", "error");
      }
    });
    return;
  }

  // Add price list data API
  const add_price = async () => {
    try {
      const add = {};
      add.j_unique_id = j_unique_id;
      add.name = name;
      add.price = price;
      add.category_name = categoryName;

      const response = await addPrice(add);

      // flash message response
      if (response.success) {
        notify("Price added successfully", "success");
      } else {
        console.log(response);
        notify(`${response.data.message}`, "error");
      }
      setCategoryName("Gold");
      // fetch the latest changes
      await get_price();
    } catch (error) {
      console.log("Add price API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  };

  // Get price list data API
  const get_price = async () => {
    try {
      const response = await getPrice();
      setPriceListData(response.data.data);
    } catch (error) {
      console.log("Get Price API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  };

  // Update price list data API
  const update_price = async () => {
    try {
      const update = {};
      update.name = name;
      update.price = price;
      update.id = priceId;
      update.category_name = categoryName;

      const response = await updatePrice(update);

      // flash message response
      if (response.success) {
        notify("Price updated successfully", "success");
      } else {
        notify(`${response.data.message}`, "error");
      }
      setCategoryName("Gold");

      // fetch latest changes
      await get_price();
    } catch (error) {
      console.log("Update price API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  };

  // Delete price list data API
  const delete_price = async (id) => {
    try {
      const response = await deletePrice(id);

      // flash message response
      if (response.success) {
        notify("Price item removed successfully", "success");
      }

      // fetch latest changes
      await get_price();
    } catch (error) {
      console.log("Delete price API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
      return error;
    }
  };

  // on reload fetch the price list data
  useEffect(() => {
    const fetchData = async () => {
      await get_price();
    };
    (async () => await fetchData())();
  }, []);

  return (
    <div className={styles.container}>
      {/* Add Price Modal */}
      <Modal size="lg" isOpen={addModal} toggle={() => setAddModal(!addModal)}>
        <ModalHeader
          className="AddJewellerHeader"
          toggle={() => setAddModal(!addModal)}
        >
          {"Add Price"}
        </ModalHeader>
        <ModalBody className="AddJewellerBody">
          <form>
            {/* Price Name */}
            <div className="form-group row my-3">
              <label for="priceName" className="col-3 col-form-label">
                Price Name
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter Price Name.."
                  required
                ></Input>
              </div>
            </div>
            {/* Price */}
            <div className="form-group row my-3">
              <label for="price" className="col-3 col-form-label">
                Price
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="price"
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  placeholder="Enter Price .."
                  required
                ></Input>
              </div>
            </div>
            {/* Category */}
            <div className="form-group row my-3">
              <label for="category" className="col-3 col-form-label">
                Category Name
              </label>
              <div className="col-9">
                <div className="custom-select-wrapper">
                  <select
                    className="form-control custom-select"
                    id="category"
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                    }}
                    required
                  >
                    {/* Provide some values for the dropdown options */}
                    {category.map((item, index) => {
                      return (
                        <option key={index} value={item.category_name}>
                          {item?.category_name?.charAt(0)?.toUpperCase() +
                            item?.category_name?.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary col-4"
            variant="secondary"
            onClick={() => setAddModal(!addModal)}
          >
            Cancle
          </button>
          <button
            type="submit"
            className="btn btn-primary col-4"
            variant="primary"
            disabled={name && price ? false : true}
            onClick={() => {
              add_price();
              setAddModal(!addModal);
            }}
          >
            Add Price
          </button>
        </ModalFooter>
      </Modal>
      {/* Modify price modal */}
      <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader
          className="AddJewellerHeader"
          toggle={() => setModal(!modal)}
        >
          {modalTitleName}
        </ModalHeader>
        <ModalBody className="AddJewellerBody">
          <form>
            {/* Price index */}
            <div className="form-group row my-3">
              <label for="priceIndex" className="col-3 col-form-label">
                Index
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="index"
                  onChange={(e) => {
                    setIndex(e.target.value);
                  }}
                  value={index}
                  placeholder="Enter Price Index.."
                  disabled={true}
                  required
                ></Input>
              </div>
            </div>
            {/* Price Name */}
            <div className="form-group row my-3">
              <label for="name" className="col-3 col-form-label">
                Name
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  id="name"
                  placeholder="Enter Price Name.."
                  value={name}
                  required
                ></Input>
              </div>
            </div>
            {/* Price */}
            <div className="form-group row my-3">
              <label for="price" className="col-3 col-form-label">
                Price
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="price"
                  placeholder="Enter Price.."
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  value={price}
                  required
                ></Input>
              </div>
            </div>
            {/* Category */}
            <div className="form-group row my-3">
              <label for="category" className="col-3 col-form-label">
                Category Name
              </label>
              <div className="col-9">
                <div className="custom-select-wrapper">
                  <select
                    className="form-control custom-select"
                    id="category"
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                    }}
                    required
                  >
                    {/* Provide some values for the dropdown options */}
                    {category.map((item, index) => {
                      return (
                        <option key={index} value={item.category_name}>
                          {item.category_name.charAt(0).toUpperCase() +
                            item.category_name.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary col-4"
            variant="secondary"
            onClick={() => setModal(!Modal)}
          >
            Close
          </button>
          {modalTitleName === "Modify Details" ? (
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                update_price();
                setModal(!Modal);
              }}
            >
              Save Changes
            </button>
          ) : null}
        </ModalFooter>
      </Modal>
      {/* Table view */}
      <div className={styles.pricelist}>
        <div className={styles.cardheader}>
          <h2>Price List</h2>
          <div
            className={styles.cardheaderrightview}
            style={{ justifyContent: "flex-end" }}
          >
            <i
              className="bi bi-person-fill-add"
              onClick={() => {
                setAddModal(!addModal);
              }}
            ></i>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td style={{ color: "#0E3386" }}>Index</td>
              <td>Name</td>
              <td>Price</td>
              <td>Category Name</td>
              <td>Action</td>
              <td>Details</td>
            </tr>
          </thead>
          <tbody>
            {priceListData &&
              priceListData.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td style={{ maxWidth: 100, textAlign: "justify" }}>
                      {item.name}
                    </td>
                    <td>{item.price}</td>
                    <td>
                      {item?.category_name?.charAt(0)?.toUpperCase() +
                        item?.category_name?.slice(1)}
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square btn"
                        className="editIcon"
                        style={{ marginRight: 10, color: "#e3ff0f" }}
                        onClick={() => {
                          modalFieldValue(index);
                          modalTitle("Modify Details");
                          setPriceId(item.id);
                          setModal(!modal);
                        }}
                      />
                      <FontAwesomeIcon
                        icon="fa-solid fa-trash"
                        className="deleteIcon"
                        onClick={() => {
                          setPriceId(item.id);
                          deleteSwal(index);
                        }}
                        style={{ color: "#f50000" }}
                      />
                    </td>
                    <td>
                      <i
                        className="bi bi-arrow-right-circle detailIcon"
                        onClick={() => {
                          modalFieldValue(index);
                          modalTitle("Details");
                          setModal(!modal);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceList;
