import React, { useEffect, useState } from "react";
import styles from "./ImageSlider.module.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notify } from "../FlashMessage/ToastContainer";
import {
  addImage,
  deleteImage,
  getImage,
  updateImage,
} from "../../API/Image/imageApi";
import { j_unique_id } from "../../Constant/Constant";
import { useNavigate } from "react-router-dom";

const ImageSlider = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const container = document.getElementById("image");
    const image = document.getElementById("id1");

    if (container && image) {
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const imageWidth = image.width;
      const imageHeight = image.height;

      const aspectRatio = imageWidth / imageHeight;

      if (aspectRatio > containerWidth / containerHeight) {
        // Image is wider than the container
        image.style.width = containerWidth + "px";
        image.style.height = "auto";
      } else {
        // Image is taller than the container
        image.style.width = "auto";
        image.style.height = containerHeight + "px";
      }
    }
  }, []);

  // modal
  const [imageData, setImageData] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // check file is less then 300 kb or not
  async function checkFileSize(files) {
    const areFilesValid = Array.from(files).every(
      (file) => file.size <= 300 * 1024
    );
    if (!areFilesValid) {
      setSelectedFiles([]);
      alert("Please select a file less than 300kb in size");
      return false;
    }
    return true;
  }

  // Set the modal field value
  function modalFieldValue(index) {
    setImageName(imageData[index].image_name);
    setImageId(imageData[index].id);
  }

  // call api for add slider image
  const add_image = async (files) => {
    try {
      const formData = new FormData();
      formData.append("image_type", 1);
      Array.from(files).forEach((file) => {
        formData.append("images", file);
      });
      const response = await addImage(formData);

      // set flash message
      if (response?.success) {
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }
      // fetch latest change
      await get_image();
    } catch (error) {
      console.log("Add Image API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // call API for delete image
  const delete_image = async (id) => {
    try {
      const response = await deleteImage(id);
      // set flash message
      if (response?.success) {
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }
      // fetch latest changes
      await get_image();
    } catch (error) {
      console.log("Delete Image API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Update Image API
  const update_image = async (files) => {
    try {
      const formData = new FormData();
      formData.append("image_type", 1);
      formData.append("id", imageId);
      formData.append("image_name", imageName);
      Array.from(files).forEach((file) => {
        formData.append("images", file);
      });
      const response = await updateImage(formData);
      // set flash message
      if (response?.success) {
        notify(`${response?.data?.message}`, "success");
      } else {
        notify(`${response?.data?.message}`, "error");
      }
      // fetch latest change
      await get_image();
    } catch (error) {
      console.log("Update Image API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Get Image API
  const get_image = async () => {
    try {
      const response = await getImage({
        j_unique_id: j_unique_id,
        image_type: 1,
      });
      if (response?.success) {
        setImageData(response?.data?.data);
      }
    } catch (error) {
      console.log("Get Image API : ", error);
      notify("Somer internal server error", "error");
      return error;
    }
  };

  useEffect(() => {
    get_image();
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.header} d-flex justify-content-between align-items-center pe-0 pt-0`}
      >
        <h2>Slider Images</h2>
        <div
          className={`${styles.cardheaderrightview} m-0 p-0`}
          style={{ justifyContent: "flex-end" }}
        >
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={async (e) => {
              const files = e.target.files;
              const isValid = await checkFileSize(files);
              if (isValid) {
                setSelectedFiles(Array.from(files));
                if (isUpdate) {
                  await update_image(files);
                } else {
                  await add_image(files);
                }
              }
            }}
            placeholder="Please select image file"
            multiple={!isUpdate}
            required
          />
          <i
            className="bi bi-person-fill-add"
            onClick={() => {
              setIsUpdate(false);
              document.getElementById("fileInput").click();
            }}
          ></i>
        </div>
      </div>
      <div className={styles.imagePreview}>
        {imageData ? (
          imageData.map((item, index) => {
            return (
              <div className={`${styles.imageContainer} border`} key={index}>
                <div className={`${styles.image}`}>
                  <img
                    src={`https://golddata.in:5800/images/${localStorage.getItem(
                      "j_unique_id"
                    )}/slider/${item.image_name}`}
                    alt={`${index}`}
                    // className="hover-zoom"
                  />
                </div>
                <div className={`${styles.action} border-top"`}>
                  <FontAwesomeIcon
                    icon="fa-solid fa-trash"
                    className={styles.deleteIcon}
                    style={{ color: "#f50000" }}
                    onClick={async () => {
                      setImageId(index);
                      await delete_image(item.id);
                    }}
                  />
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen-to-square btn"
                    className={styles.editIcon}
                    style={{ marginRight: 10, color: "#e3ff0f" }}
                    onClick={async () => {
                      setImageId(index);
                      modalFieldValue(index);
                      setIsUpdate(true);
                      document.getElementById("fileInput").click();
                    }}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <p
            style={{
              minHeight: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              margin: 0,
              padding: 0,
            }}
          >
            Image not found
          </p>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;
