import axios from "axios";
import { baseUrl, url } from "../url";
import { authToken, tokenVerification } from "../../Constant/Constant";

// Get All category API for super Admin
export const getAllCategory = async () => {
  try {
    const apiUrl = `${baseUrl}/getallcategory`;
    const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const response = await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Get All Category API >>> ", error.message);
    return error;
  }
};

// total and active category
export const categoryCount = async () => {
  try {
    const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const apiUrl = `${baseUrl}/categorycount`;
    const response = await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Category count API >>> ", error.message);
    return error;
  }
};

// add category api
export const addCategory = async (requestData) => {
  try {
    // const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const response = await axios.post(url.addCategory, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Add category API >>> ", error.message);
    return error;
  }
};

// update category api
export const updateCategory = async (requestData) => {
  try {
    await tokenVerification();
    const response = await axios.put(url.updateCategory, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Add category API >>> ", error.message);
    return error;
  }
};

// delete category api
export const deleteCategory = async (id) => {
  try {
    await tokenVerification();
    const response = await axios.delete(`${url.deleteCategory}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Delete category API >>> ", error.message);
    return error;
  }
};

// Get All category API for super Admin
export const getCategory = async () => {
  try {
    const apiUrl = url.getCategory;
    const authToken = localStorage.getItem("auth_token")?.trim();
    if (!authToken) {
      console.error("Authentication token is missing.");
      return;
    }
    const response = await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken.slice(1, -1)}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Get Category API >>> ", error.message);
    return error;
  }
};
