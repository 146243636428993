import React, { useState } from "react";
import styles from "./SignUpDetails.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { baseUrl } from "../API/url";

function SignUpDetails() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);

  // set details API
  const set_details = async () => {
    try {
      const apiUrl = `${baseUrl}/register`;
      const requestData = {
        name: userName,
        gst_number: gstNumber,
        mobile_number: mobileNumber,
        j_unique_id: localStorage.getItem("j_unique_id"),
      };
      const response = await axios.post(apiUrl, requestData);

      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
        navigate("/login");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/signupdetails");
      }
    } catch (error) {
      console.log("Set details API : ", error);
      notify("Some internal server error", "error");
    }
  };
  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>Fill Details</h2>
            </div>
            <div className={styles.UserInput} style={{ marginTop: "10px" }}>
              <p>UserName</p>
              <input
                type="text"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                placeholder="Enter User Name..."
              ></input>
            </div>
            <div className={styles.UserInput}>
              <p>GST Number</p>
              <input
                type="text"
                onChange={(e) => {
                  setGstNumber(e.target.value);
                }}
                placeholder="Enter GST Number..."
              ></input>
            </div>
            <div className={styles.UserInput}>
              <p>Mobile Number</p>
              <input
                type="text"
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
                placeholder="Enter Mobile Number..."
              ></input>
            </div>
          </div>
          <div className={styles.loginFooter}>
            <div className={styles.LoginButton}>
              <input
                type="submit"
                onClick={() => {
                  set_details();
                }}
                value={"Save"}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpDetails;
