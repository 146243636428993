import React, { useState } from "react";
import styles from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { ToastContainer } from "react-toastify";
import { baseUrl } from "../API/url";

function SignUp() {
  const navigate = useNavigate();
  const [jewellerId, setJewellerId] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // send otp for verify user
  const send_otp = async () => {
    try {
      const apiUrl = `${baseUrl}/register`;
      const requestData = {
        j_unique_id: jewellerId,
        password: password,
      };

      const response = await axios.post(apiUrl, requestData);

      if (response.data.success) {
        localStorage.setItem("j_unique_id", jewellerId);
        notify(`${response.data.data.message}`, "success");
        navigate("/verifyotp");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/signup");
      }
    } catch (error) {
      console.log("User verify API : ", error);
      notify("Some internal server error", "error");
    }
  };

  return (
    <>
      <div className={styles.container_fluid}>
        <div className={styles.loginBackground}>
          <div className={styles.loginHeader}>
            <div>
              {/* <Image  /> */}
              <img
                src={require("../assests/logo.png")}
                height={"75px"}
                width={"75px"}
                alt="Logo"
              />
            </div>
            <div>
              <h2>Retronic Technologies</h2>
            </div>
          </div>
          <div className={styles.LoginContent}>
            <div>
              <h2>SignUp</h2>
            </div>
            <div className={styles.UserInput}>
              <p>UserID</p>
              <input
                type="text"
                onChange={(e) => {
                  setJewellerId(e.target.value);
                }}
                placeholder="Enter UserID..."
              ></input>
            </div>
            <div className={styles.UserInput} style={{ position: "relative" }}>
              <p>Password</p>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password..."
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
                style={{
                  position: "absolute",
                  top: "75%",
                  right: "4%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                <i
                  className={`bi ${
                    showPassword ? "bi-eye-fill" : "bi-eye-slash-fill"
                  }`}
                  style={{
                    fontSize: "1.2em",
                    color: "#555",
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className={styles.loginFooter}>
            <hr />
            <div className={styles.LoginButton}>
              {/* <Link to="/verifyotp"> */}
              <input
                type="submit"
                onClick={() => {
                  send_otp();
                }}
                value={"Send OTP"}
              ></input>
              {/* </Link> */}
            </div>
            <div className={styles.loginSignUp}>
              <p>
                Have account?
                <Link to={"/login"} style={{ textDecoration: "none" }}>
                  {"SignIn"}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default SignUp;
