import React, { useCallback, useEffect, useState } from "react";
import "./JewellerDetails.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import { ModalBody } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../FlashMessage/ToastContainer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jeweller_id } from "../../app/Slices/sidebarToggleSlice";
import { baseUrl } from "../../API/url";

// Add the icon to the library for global use
library.add(faCircleCheck, faCircleXmark);
library.add(faPenToSquare, faPenToSquare);
library.add(faTrash, faTrash);

function JewellerDetails() {
  // redux dispatch
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [jewellerData, setJewellerData] = useState([]);
  // const [updateIndex, setUpdateIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [modalTitleName, setModalTitleName] = useState("");
  //Form Model Validation
  const [JewellersName, setJewellersName] = useState(null);
  const [jewellerMobile, setJewellerMobile] = useState(null);
  const [jewellerEmail, setJewellerEmail] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [address, setAddress] = useState(null);
  const [id, setId] = useState(null);
  const [jewellerId, setJewellerId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("active");

  // Set the modal field value
  function modalFieldValue(index) {
    setJewellersName(jewellerData[index].name);
    setJewellerEmail(jewellerData[index].email_address);
    setJewellerMobile(jewellerData[index].mobile_number);
    setGstNumber(jewellerData[index].gst_number);
    setJewellerId(jewellerData[index].j_unique_id);
    setZipcode(jewellerData[index].zipcode);
    setAddress(jewellerData[index].address);
    setSelectedStatus(jewellerData[index].status);
    setStartDate(jewellerData[index].start_date);
    setDueDate(jewellerData[index].due_date);
  }

  // Form Validation
  // function ValidationFormModel() {
  // jewellerData[index].name = JewellersName;
  //   if (JewellersName.length === 0) {
  //     Swal.fire({
  //       title: "Failed",
  //       text: "Please Enter Name!!!",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //       width: "400px",
  //       confirmButtonColor: "0E3386",
  //       color: "red",
  //       position: "top",
  //       showClass: {
  //         popup: "animate__animated animate__fadeInDown",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__fadeOutUp",
  //       },
  //     });
  //     return;
  //   }
  //   setModal(!Modal);
  // }

  // Set Modal title
  function modalTitle(name) {
    setModalTitleName(name);
  }

  // delete jeweller API
  const delete_jeweller = async (id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/deletejeweller/${id}`;

      const response = await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      // fetch latest changes
      await getjewellers();

      // Respone with flash message
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete data API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // update jeweller details API
  const update_jeweller = async (id) => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/updatejeweller`;
      const requestData = {};
      requestData.name = JewellersName;
      requestData.status = selectedStatus;
      requestData.zipcode = zipcode;
      requestData.address = address;
      requestData.mobile_number = jewellerMobile;
      requestData.gst_number = gstNumber;
      requestData.id = id;

      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      // fetch latest changes
      await getjewellers();

      // Flash message with reponse
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Update jeweller API : ", error);
      notify("Some internal server", "error");
      navigate("/login");
    }
  };

  // add jeweller api call
  const addJewellerApi = async (mail) => {
    try {
      const apiUrl = `${baseUrl}/addjeweller`;
      const auth_token = localStorage.getItem("auth_token");

      // request body data
      const requestData = {
        email_address: mail,
      };

      // Make the POST request
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${auth_token.slice(1, -1)}`,
        },
      });

      // Close the modal or perform other actions on successful response
      setAddModal(false);

      if (response.data.success) {
        // Show success
        notify("Mail send successfully", "success");
      } else {
        // show error
        notify(`${response.data.data.email_address}`, "error");
      }
    } catch (error) {
      // Handle errors
      console.error("Error sending mail:", error);

      // Show error
      notify("Failed to send mail. Please try again.", "error");
    }
  };

  // jeweller full details preview API
  // const jewellerDetailsApi = async (index) => {
  //   try {
  //     const authToken = localStorage.getItem("auth_token")?.trim();
  //     if (!authToken) {
  //       console.error("Authentication token is missing.");
  //       navigate("/login");
  //       return;
  //     }
  //     const apiUrl = `${baseUrl}/getjewellers`;
  //     const requestData = {
  //       j_unique_id: null,
  //     };
  //     const response = await axios.post(`${apiUrl}`, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `${authToken.slice(1, -1)}`,
  //       },
  //     });
  //     setJewellerData(response.data.data.data);
  //     console.log("jewellers api response >>>>>>>>> ", response.data.data.data);
  //   } catch (error) {
  //     console.log("Api Error", error);
  //     notify("Some internal server error", "error");
  //   }
  // };

  // call api for the last 5 jeweller data
  const getjewellers = useCallback(async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/getjewellers`;
      const requestData = {
        j_unique_id: null,
      };
      const response = await axios.post(`${apiUrl}`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      setJewellerData(response.data.data.data);
    } catch (error) {
      console.log("Api Error", error);
      notify("Some internal server error", "error");
    }
  }, [navigate]);

  // set jeweller unique id and navigate to the customer menu
  async function navigation(id) {
    await dispatch(jeweller_id(id));
    navigate("/customer");
  }

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${jewellerData[index].name}`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setCustomerId callback to get the latest value
        setId((prevId) => {
          delete_jeweller(prevId);
          return prevId; // Return the updated value
        });
      } else {
        Swal.fire("Cancelled", "Your Customer is safe :)", "error");
      }
    });
    return;
  }

  // call api when page load
  useEffect(() => {
    const fetch_data = async () => {
      await getjewellers();
    };
    fetch_data();
  }, [getjewellers]);

  return (
    <>
      <div className="CustomerDetails">
        <Modal
          size="lg"
          isOpen={addModal}
          toggle={() => {
            getjewellers();
            setAddModal(!addModal);
          }}
        >
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => {
              getjewellers();
              setAddModal(!addModal);
            }}
          >
            {"Add Jeweller"}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerEmail"
                  className={`col-3 col-form-label`}
                >
                  Jeweller Email
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="#"
                    onChange={(e) => {
                      setJewellerEmail(e.target.value);
                    }}
                    placeholder="Enter Jeweller Email.."
                    required
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => {
                setJewellerEmail("");
                setAddModal(!addModal);
              }}
            >
              Cancle
            </button>
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                addJewellerApi(jewellerEmail);
                setAddModal(!addModal);
                setJewellerEmail("");
              }}
            >
              Send Mail
            </button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader
            className={`AddJewellerHeader`}
            toggle={() => {
              setJewellerEmail("");
              setModal(!modal);
            }}
          >
            {modalTitleName}
          </ModalHeader>
          <ModalBody className={`AddJewellerBody`}>
            <form>
              {/* Jeweller Unique Id */}
              <div className={`form-group row my-3`}>
                <label htmlFor="StaticID" className={`col-3 col-form-label`}>
                  Jeweller ID
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    readOnly
                    className={`AddJewellerInput form-control-plaintext`}
                    id="#"
                    value={jewellerId}
                    required
                    disabled
                    style={{ paddingLeft: 13 }}
                  />
                </div>
              </div>
              {/* Jeweller Name */}
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerName"
                  className={`col-3 col-form-label`}
                >
                  Jeweller Name
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    onChange={(e) => {
                      setJewellersName(e.target.value);
                    }}
                    className={`form-control`}
                    id="jewellerName"
                    placeholder="Enter Jeweller Name.."
                    value={JewellersName}
                    required
                  />
                </div>
              </div>
              {/* Jeweller Mobile Number */}
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerMobileNo"
                  className={`col-3 col-form-label`}
                >
                  Jeweller MobileNo
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="#"
                    onChange={(e) => {
                      setJewellerMobile(e.target.value);
                    }}
                    placeholder="Enter Jeweller MobileNo.."
                    value={jewellerMobile}
                    required
                  />
                </div>
              </div>
              {/* Jeweller Email */}
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerEmail"
                  className={`col-3 col-form-label`}
                >
                  Jeweller Email
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="#"
                    onChange={(e) => {
                      setJewellerEmail(e.target.value);
                    }}
                    placeholder="Enter Jeweller Email.."
                    value={jewellerEmail}
                    disabled={true}
                    required
                  />
                </div>
              </div>
              {/* GST Number */}
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerGSTNo"
                  className={`col-3 col-form-label`}
                >
                  Jeweller GSTNo
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="gstNumber"
                    onChange={(e) => {
                      setGstNumber(e.target.value);
                    }}
                    value={gstNumber}
                    placeholder="Enter Jeweller GSTNo.."
                    required
                  />
                </div>
              </div>
              {/* Start date */}
              <div className={`form-group row my-3`}>
                <label htmlFor="start date" className={`col-3 col-form-label`}>
                  Start Date
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="#"
                    placeholder="Customer start date..."
                    value={startDate}
                    required
                    disabled
                  />
                </div>
              </div>
              {/* Due date */}
              <div className={`form-group row my-3`}>
                <label
                  htmlFor="inputJewellerUPI"
                  className={`col-3 col-form-label`}
                >
                  Due Date
                </label>
                <div className={`col-9`}>
                  <Input
                    type="text"
                    className={`form-control`}
                    id="#"
                    placeholder="Customer due date..."
                    value={dueDate}
                    required
                    disabled
                  />
                </div>
              </div>
              {/* Status */}
              <div className={`form-group row my-3`}>
                <label htmlFor="inputStatus" className={`col-3 col-form-label`}>
                  {`Status`}
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="statusDropdown" // Corrected id attribute
                      required
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      value={selectedStatus}
                    >
                      {/* Provide some values for the dropdown options */}
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* zipcode */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputJewellerZipcode"
                  className="col-3 col-form-label"
                >
                  Jeweller Zipcode
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    onChange={(e) => {
                      setZipcode(e.target.value);
                    }}
                    value={zipcode}
                    placeholder="Enter Jeweller Zipcode.."
                    required
                  />
                </div>
              </div>
              {/* Address */}
              <div className="form-group row my-3">
                <label
                  htmlFor="inputCustomerAddress"
                  className="col-3 col-form-label"
                >
                  {`Jeweller Address`}
                </label>
                <div className="col-9">
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                  ></textarea>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => {
                setModal(!Modal);
                setJewellerEmail("");
                getjewellers();
              }}
            >
              Close
            </button>
            {modalTitleName === "Modify Jeweller" ? (
              <button
                type="submit"
                className="btn btn-primary col-4"
                variant="primary"
                onClick={() => {
                  update_jeweller(id);
                  setModal(!Modal);
                }}
              >
                Save Changes
              </button>
            ) : null}
          </ModalFooter>
        </Modal>
        <div className="CustomerList">
          <div className="cardHeader">
            <div>
              <h2>All Jeweller</h2>
            </div>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <i
                className="bi bi-person-fill-add"
                onClick={() => {
                  setAddModal(!addModal);
                }}
              ></i>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Jeweller Name</td>
                <td>Jeweller Mobile</td>
                <td>Jeweller EmailId</td>
                <td>Status</td>
                <td>Action</td>
                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              {jewellerData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      navigation(item?.j_unique_id);
                    }}
                  >
                    <td>{item.name}</td>
                    <td>{item.mobile_number}</td>
                    <td>{item.email_address}</td>
                    <td>
                      {item.status !== "active" ? (
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          style={{ color: "#FF0000" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          style={{ color: "#5fb363" }}
                        />
                      )}
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square btn"
                        className="editIcon"
                        style={{ marginRight: 10, color: "#e3ff0f" }}
                        onClick={() => {
                          modalTitle("Modify Jeweller");
                          modalFieldValue(index);
                          setId(item.id);
                          setModal(!modal);
                        }}
                      />
                      <FontAwesomeIcon
                        className="deleteIcon"
                        icon="fa-solid fa-trash"
                        style={{ color: "#f50000" }}
                        onClick={() => {
                          setId(item.id);
                          deleteSwal(index);
                        }}
                      />
                    </td>
                    <td>
                      <i
                        className={`bi bi-arrow-right-circle detailIcon`}
                        onClick={() => {
                          modalTitle("Jeweller Details");
                          modalFieldValue(index);
                          setModal(true);
                        }}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default JewellerDetails;
