// baseurl
export const baseUrl = `https://golddata.in:5800/api/v1`;

export const url = {
  // Category url
  addCategory: `${baseUrl}/addcategory`,
  updateCategory: `${baseUrl}/updatecategory`,
  deleteCategory: `${baseUrl}/deletecategory`,
  getCategory: `${baseUrl}/getcategory`,
  // -- Category url
  // Market price url
  addPrice: `${baseUrl}/addprice`,
  getPrice: `${baseUrl}/getprice`,
  updatePrice: `${baseUrl}/updateprice`,
  deletePrice: `${baseUrl}/deleteprice`,
  // -- Market price url
  // Image url
  deleteImage: `${baseUrl}/deleteimage`,
  getImage: `${baseUrl}/getimage`,
  addImage: `${baseUrl}/addimage`,
  updateImage: `${baseUrl}/updateimage`,
  // -- Image url
  // Transaction
  getTransaction: `${baseUrl}/gettransaction`,
  addTransaction: `${baseUrl}/addtransaction`,
  // -- Transaction
  // Installment
  getInstallment: `${baseUrl}/get-installment`,
  addInstallment: `${baseUrl}/add-installment`,
  // -- Installment
  // Card Reader
  readcard: `${baseUrl}/readcard`,
  // -- Card Reader
};
