import React, { useCallback, useEffect, useState } from "react";
import styles from "./ProfileDetails.module.css";
import { ModalBody } from "react-bootstrap";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { notify } from "../FlashMessage/ToastContainer";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { j_unique_id } from "../../Constant/Constant";
import { baseUrl } from "../../API/url";

export default function ProfileDetails() {
  const navigate = useNavigate();
  //Modal Toggle
  const [modal, setModal] = useState(false);
  const [name, setName] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [address, setAddress] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [jewellerId, setJewellerId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [id, setId] = useState();
  const userRole = parseInt(localStorage.getItem("user_role"));

  // Update jeweller details
  const update_details = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }

      const apiUrl = `${baseUrl}/updatejeweller`;
      const requestData = {};
      console.log("id >>>>> ", id);
      requestData.name = name;
      requestData.status = selectedStatus;
      requestData.zipcode = zipcode;
      requestData.address = address;
      requestData.mobile_number = mobileNumber;
      requestData.gst_number = gstNumber;
      requestData.id = id;

      const response = await axios.put(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });

      // fetch latest data
      await jeweller_details();

      // Flashmessage for response
      if (response.data.success) {
        notify("Details updated !!", "success");
      } else {
        notify(`${response.data.data.message}`, "error");
        navigate("/login");
      }
    } catch (error) {
      console.log("Update jeweller details API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // Get Particular Jeweller Details
  const jeweller_details = useCallback(async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/getjewellers`;
      const requestData = {
        j_unique_id: j_unique_id,
        user_role: userRole,
      };
      const response = await axios.post(`${apiUrl}`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      console.log("response >>> ", response.data.data);
      setId(response.data.data.data[0].id);
      setEmailAddress(response.data.data.data[0].email_address);
      setAddress(response.data.data.data[0].address);
      setDueDate(response.data.data.data[0].due_date);
      setStartDate(response.data.data.data[0].start_date);
      setName(response.data.data.data[0].name);
      setJewellerId(response.data.data.data[0].j_unique_id);
      setGstNumber(response.data.data.data[0].gst_number);
      setMobileNumber(response.data.data.data[0].mobile_number);
      setZipcode(response.data.data.data[0].zipcode);
    } catch (error) {
      console.log("Jeweller details API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  }, [navigate, userRole]);

  useEffect(() => {
    const fetch_data = async () => {
      await jeweller_details();
    };
    fetch_data();
  }, [jeweller_details]);

  return (
    <>
      <div className={styles.container}>
        {/* Edit Field Modal */}
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader
            className="AddJewellerHeader"
            toggle={() => setModal(!modal)}
          >
            {`Modify Jeweller`}
          </ModalHeader>
          <ModalBody className="AddJewellerBody">
            <form>
              <div className="form-group row my-3">
                <label for="ID" className="col-3 col-form-label ">
                  ID
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="AddJewellerInput form-control-plaintext"
                    id="jewellerid"
                    value={jewellerId}
                    disabled={true}
                    required
                    style={{ paddingLeft: 13 }}
                  />
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="jewellerName" className="col-3 col-form-label">
                  Jeweller Name
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                    className="form-control"
                    id="name"
                    placeholder="Name.."
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="MobileNo" className="col-3 col-form-label">
                  Mobile Number
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="mobileNumber"
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                    placeholder="Mobile Number..."
                    value={mobileNumber}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="email" className="col-3 col-form-label">
                  Email Address
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="emailAddress"
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                    placeholder="Email address.."
                    value={emailAddress}
                    disabled
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="GSTNo" className="col-3 col-form-label">
                  GST No
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="gstNumber"
                    placeholder="GST Number..."
                    onChange={(e) => {
                      setGstNumber(e.target.value);
                    }}
                    value={gstNumber}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="inputStatus" className="col-3 col-form-label">
                  {`Status`}
                </label>
                <div className="col-9">
                  <div className="custom-select-wrapper">
                    <select
                      className="form-control custom-select"
                      id="statusDropdown" // Corrected id attribute
                      required
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                      value={selectedStatus}
                    >
                      {/* Provide some values for the dropdown options */}
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row my-3">
                <label for="zipcode" className="col-3 col-form-label">
                  Zipcode
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    onChange={(e) => {
                      setZipcode(e.target.value);
                    }}
                    placeholder="Zipcode..."
                    value={zipcode}
                    required
                  ></Input>
                </div>
              </div>
              <div className="form-group row my-3">
                <label
                  for="inputCustomerAddress"
                  className="col-3 col-form-label"
                >
                  {`Address`}
                </label>
                <div className="col-9">
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                  ></textarea>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary col-4"
              variant="secondary"
              onClick={() => setModal(!modal)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                update_details();
                setModal(!modal);
              }}
            >
              Save Changes
            </button>
          </ModalFooter>
        </Modal>
        {/* Title of Profile */}
        <div className={styles.title}>
          <p>Profile</p>
        </div>
        {/* // Title of Profile */}
        {/* Profile field */}
        <div className={styles.fields}>
          {/* Name Field */}
          <div className={styles.field}>
            <label>Name</label>
            <input type="text" value={name} placeholder={`${name}`} required />
          </div>
          {/* Mobile number Field */}
          <div className={styles.field}>
            <label>Mobile Number</label>
            <input
              type="text"
              value={mobileNumber}
              placeholder={`${mobileNumber}`}
            />
          </div>
          {/* Email Address Field */}
          <div className={styles.field}>
            <label>Email Address</label>
            <input
              type="email"
              value={emailAddress}
              placeholder={`${emailAddress}`}
            />
          </div>
          {/* GST No Field */}
          <div className={styles.field}>
            <label>GST No</label>
            <input type="text" value={gstNumber} placeholder={`${gstNumber}`} />
          </div>
          {/* Start Date */}
          <div className={styles.field}>
            <label>Start Date</label>
            <input
              type="text"
              value={startDate}
              placeholder={`${startDate}`}
              disabled
            />
          </div>
          {/* Due Date */}
          <div className={styles.field}>
            <label>Due Date</label>
            <input
              type="text"
              value={dueDate}
              placeholder={`${dueDate}`}
              disabled
              style={{ border: "1px solid #dc1d20" }}
            />
          </div>
          {/* Address Field */}
          <div className={styles.field}>
            <label>Address</label>
            <textarea value={address} rows="2">
              {address}
            </textarea>
          </div>
        </div>
        {/* <--Profile content> */}
        {/* <Edit Field Button> */}
        <div className={styles.editButton}>
          <button
            type="submit"
            className="btn btn-primary col-lg-2 col-md-2 col-xxl-2"
            variant="primary"
            onClick={() => {
              setModal(!modal);
            }}
          >
            Modify Details
          </button>
        </div>
        {/* <--Edit Field Button> */}
      </div>
      <ToastContainer />
    </>
  );
}
