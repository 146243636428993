import React, { useEffect, useState } from "react";
import "./CustomerDetails.css";
import { Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import { ModalBody } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { notify } from "../FlashMessage/ToastContainer";
import axios from "axios";
import { baseUrl } from "../../API/url";

// Add the icon to the library for global use
library.add(faCircleCheck, faCircleXmark);
library.add(faPenToSquare, faPenToSquare);
library.add(faTrash, faTrash);

function CustomerDetails() {
  const navigate = useNavigate();
  // User Role
  const userRole = parseInt(localStorage.getItem("user_role"));
  const [jewellerData, setJewellerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [titleName, setTitleName] = useState("");
  // Modal
  const [modalTitleName, setModalTitleName] = useState("");
  const [modal, setModal] = useState(false);
  //Form Model Validation
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [jewellerId, setJewellerId] = useState(null);
  const [credit, setCredit] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState("active");
  const [selectedStatus, setSelectedStatus] = useState();
  const [address, setAddress] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [id, setId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [gstNumber, setGstNumber] = useState(null);
  const [balance, setBalance] = useState(null);

  // call api for the last 5 jeweller data
  const latestCustomer = async () => {
    try {
      const requestData = {
        j_unique_id: localStorage.getItem("j_unique_id"),
      };
      const apiUrl = `${baseUrl}/customer/getcustomer`;
      const response = await axios.post(apiUrl, requestData);
      setCustomerData(response.data.data.data);
      setUserData(response.data.data.data);
      if (userRole === 1) {
        setTitleName("Jeweller");
      } else {
        setTitleName("Customer");
      }
    } catch (error) {
      console.log("Api Error", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // call api for the last 5 jeweller data
  const latestJeweller = async () => {
    try {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      const apiUrl = `${baseUrl}/getjewellers`;
      const requestData = {
        j_unique_id: null,
      };
      const response = await axios.post(`${apiUrl}`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken.slice(1, -1)}`,
        },
      });
      setJewellerData(response.data.data.data);
      setUserData(response.data.data.data);
      if (userRole === 1) {
        setTitleName("Jeweller");
      } else {
        setTitleName("Customer");
      }
    } catch (error) {
      console.log("Api Error", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // call api for the update data
  const update_data = async (id) => {
    try {
      // variable declaration
      var response;
      var apiUrl = ``;
      if (userRole === 1) {
        apiUrl = `${baseUrl}/updatejeweller`;
      } else {
        apiUrl = `${baseUrl}/customer/updatecustomer`;
      }
      const requestData = {};

      requestData.name = name;
      requestData.card_id = cardId;
      requestData.card_credit = credit;
      requestData.status = selectedStatus;
      requestData.zipcode = zipcode;
      requestData.address = address;
      requestData.mobile_number = mobileNumber;
      requestData.gst_number = gstNumber;
      requestData.id = id;

      if (userRole === 1) {
        const authToken = localStorage.getItem("auth_token")?.trim();
        if (!authToken) {
          console.error("Authentication token is missing.");
          // Handle the case where the authentication token is missing.
          navigate("/login");
          return;
        }
        response = await axios.put(apiUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authToken.slice(1, -1)}`,
          },
        });
      } else {
        response = await axios.put(apiUrl, requestData);
      }

      // fetch latest changes
      if (userRole === 1) {
        await latestJeweller();
      } else {
        await latestCustomer();
      }
      // Respone with flash message
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Update data API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // call api for the delete data
  const delete_data = async (id) => {
    try {
      // variable declaration
      var response;
      var apiUrl = ``;
      if (userRole === 1) {
        apiUrl = `${baseUrl}/deletejeweller/${id}`;
      } else {
        apiUrl = `${baseUrl}/customer/delete/${id}`;
      }

      if (userRole === 1) {
        const authToken = localStorage.getItem("auth_token")?.trim();
        if (!authToken) {
          console.error("Authentication token is missing.");
          // Handle the case where the authentication token is missing.
          navigate("/login");
          return;
        }
        response = await axios.delete(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authToken.slice(1, -1)}`,
          },
        });
      } else {
        response = await axios.delete(apiUrl);
      }

      // fetch latest changes
      if (userRole === 1) {
        await latestJeweller();
      } else {
        await latestCustomer();
      }
      // Respone with flash message
      if (response.data.success) {
        notify(`${response.data.data.message}`, "success");
      } else {
        notify(`${response.data.data.message}`, "error");
      }
    } catch (error) {
      console.log("Delete data API : ", error);
      notify("Some internal server error", "error");
      navigate("/login");
    }
  };

  // call api when page load
  useEffect(() => {
    const onLoad = async () => {
      if (userRole === 1) {
        await latestJeweller();
      } else {
        await latestCustomer();
      }
    };
    (async () => await onLoad())();
  }, []);

  // set user Role missing here......................................

  // Set the modal field value
  function modalFieldValue(index) {
    setName(userData[index].name);
    setEmail(userData[index].email_address);
    setMobileNumber(userData[index].mobile_number);
    setSelectedFiles(userData[index].status);
    setAddress(userData[index].address);
    setZipcode(userData[index].zipcode);
    setStartDate(userData[index].start_date);
    setDueDate(userData[index].due_date);
    setBalance(userData[index].balance);
    if (userRole === 1) {
      setJewellerId(jewellerData[index].j_unique_id);
      setGstNumber(jewellerData[index].gst_number);
    } else {
      setCredit(customerData[index].card_credit);
      setCustomerId(userData[index].c_unique_id);
      setCardId(userData[0].card_id);
    }
  }

  // Form Validation
  // function ValidationFormModel() {
  // jewellerData[index].name = JewellersName;
  //   if (name.length === 0) {
  //     Swal.fire({
  //       title: "Failed",
  //       text: "Please Enter Name!!!",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //       width: "400px",
  //       confirmButtonColor: "0E3386",
  //       color: "red",
  //       position: "top",
  //       showClass: {
  //         popup: "animate__animated animate__fadeInDown",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__fadeOutUp",
  //       },
  //     });
  //     return;
  //   }
  //   setModal(!Modal);
  // }

  // Delete Swal
  function deleteSwal(index) {
    Swal.fire({
      // title: "Failed",
      text: `Are you sure ? ${userData[index].name}`,
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E3386",
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
      width: "400px",
      // confirmButtonColor: "0E3386",
      color: "red",
      position: "top",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Use setCustomerId callback to get the latest value
        setId((prevId) => {
          delete_data(prevId);
          return prevId; // Return the updated value
        });
      } else {
        Swal.fire("Cancelled", "Your Customer is safe :)", "error");
      }
    });
    return;
  }

  // Set Modal title
  function modalTitle(name) {
    setModalTitleName(name);
  }

  return (
    <div className="CustomerDetails">
      <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader
          className="AddJewellerHeader"
          toggle={() => setModal(!modal)}
        >
          {modalTitleName}
        </ModalHeader>
        <ModalBody className="AddJewellerBody">
          <form>
            <div className="form-group row my-3">
              <label for="StaticID" className="col-3 col-form-label ">
                {`${titleName} ID`}
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  readonly
                  className="AddJewellerInput form-control-plaintext"
                  id="#"
                  value={userRole === 1 ? jewellerId : customerId}
                  required
                  disabled
                  style={{ paddingLeft: 13 }}
                />
              </div>
            </div>
            <div className="form-group row my-3">
              <label for="inputJewellerName" className="col-3 col-form-label">
                {`${titleName} Name`}
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className="form-control"
                  id="#"
                  placeholder={`Enter ${titleName} Name...`}
                  value={name}
                  required
                />
              </div>
            </div>
            {/* Customer card id */}
            {userRole !== 1 ? (
              <div className="form-group row my-3">
                <label for="inputJewellerName" className="col-3 col-form-label">
                  {`Card Id`}
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    onChange={(e) => {
                      setCardId(e.target.value);
                    }}
                    className="form-control"
                    id="#"
                    placeholder={`Enter card id...`}
                    value={cardId}
                    required
                  />
                </div>
              </div>
            ) : null}
            {/* GST No of jeweller and Credit of customer */}
            {userRole === 1 ? (
              <div className="form-group row my-3">
                <label
                  for="inputJewellerGSTNo"
                  className="col-3 col-form-label"
                >
                  Jeweller GSTNo
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="gstnumber"
                    onChange={(e) => {
                      setGstNumber(e.target.value);
                    }}
                    value={gstNumber}
                    placeholder="Enter Jeweller GSTNo.."
                    required
                  />
                </div>
              </div>
            ) : (
              <div className="form-group row my-3">
                <label
                  for="inputJewellerGSTNo"
                  className="col-3 col-form-label"
                >
                  Customer balance
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control"
                    id="#"
                    placeholder="Enter Customer Balance.."
                    onChange={(e) => {
                      setCredit(e.target.value);
                    }}
                    value={balance}
                    required
                    disabled
                  />
                </div>
              </div>
            )}
            {/* Email Address */}
            <div className="form-group row my-3">
              <label for="inputJewellerEmail" className="col-3 col-form-label">
                {`${titleName} Email`}
              </label>
              <div className="col-9">
                <Input
                  type="email"
                  className="form-control"
                  id="#"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter Email.."
                  value={email}
                  disabled={true}
                  required
                />
              </div>
            </div>
            {/* Mobile No */}
            <div className="form-group row my-3">
              <label for="inputMobileNo" className="col-3 col-form-label">
                {`${titleName} Mobile No`}
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="#"
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                  placeholder="Enter Mobile No.."
                  value={mobileNumber}
                  maxLength={10}
                  required
                />
              </div>
            </div>
            {/* Start date */}
            <div className="form-group row my-3">
              <label for="inputJewellerUPI" className="col-3 col-form-label">
                Start Date
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="#"
                  placeholder="Customer start date..."
                  value={startDate}
                  required
                  disabled
                ></Input>
              </div>
            </div>
            {/* Due date */}
            <div className="form-group row my-3">
              <label for="inputJewellerUPI" className="col-3 col-form-label">
                Due Date
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control "
                  id="#"
                  placeholder="Customer due date..."
                  value={dueDate}
                  required
                  disabled
                ></Input>
              </div>
            </div>
            {/* Status */}
            <div className="form-group row my-3">
              <label for="inputStatus" className="col-3 col-form-label">
                {`${titleName} Status`}
              </label>
              <div className="col-9">
                <div className="custom-select-wrapper">
                  <select
                    className="form-control custom-select"
                    id="statusDropdown" // Corrected id attribute
                    required
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                    }}
                    value={selectedStatus}
                  >
                    {/* Provide some values for the dropdown options */}
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            {/* Zipcode for jeweller and customer */}
            <div className="form-group row my-3">
              <label
                for="inputJewellerZipcode"
                className="col-3 col-form-label"
              >
                {`${titleName} Zipcode`}
              </label>
              <div className="col-9">
                <Input
                  type="text"
                  className="form-control"
                  id="#"
                  placeholder="Enter Zipcode.."
                  onChange={(e) => {
                    setZipcode(e.target.value);
                  }}
                  value={zipcode}
                  required
                />
              </div>
            </div>
            {/* Jeweller Upi-ID */}
            {userRole === 2 ? (
              <div className="form-group row my-3">
                <label for="inputJewellerUPI" className="col-3 col-form-label">
                  Jeweller UPI-ID
                </label>
                <div className="col-9">
                  <Input
                    type="text"
                    className="form-control "
                    id="#"
                    placeholder="Enter Jeweller UPI-ID.."
                    required
                  />
                </div>
              </div>
            ) : null}
            {/* Jeweller Upi-Scanner file */}
            {userRole === 2 ? (
              <div className="form-group row my-3">
                <label for="inputJewellerUPI" className="col-3 col-form-label">
                  Jeweller UPI-Scanner
                </label>
                <div className="custom-file col-9">
                  <Input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    required
                  />
                </div>
              </div>
            ) : null}
            {/* Address */}
            <div className="form-group row my-3">
              <label
                for="inputCustomerAddress"
                className="col-3 col-form-label"
              >
                {`${titleName} Address`}
              </label>
              <div className="col-9">
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                ></textarea>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary col-4"
            variant="secondary"
            onClick={() => setModal(!Modal)}
          >
            Close
          </button>
          {modalTitleName === "Modify Customer" ? (
            <button
              type="submit"
              className="btn btn-primary col-4"
              variant="primary"
              onClick={() => {
                update_data(id);
                setModal(!Modal);
              }}
            >
              Save Changes
            </button>
          ) : null}
        </ModalFooter>
      </Modal>
      {userRole !== 0 ? (
        /* Last 5 Jeweller Details For Super Admin */
        <div className="CustomerList">
          <div className="cardHeader">
            <h2>Last 5 Jeweller</h2>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <Link to="/jeweller" className="btn">
                View All
              </Link>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Jeweller Name</td>
                <td>Jeweller Mobile</td>
                <td>Jeweller EmailId</td>
                <td>Status</td>
                <td>Action</td>
                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              {jewellerData.map((item, index) => {
                if (index < 5) {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.mobile_number}</td>
                      <td>{item.email_address}</td>
                      <td>
                        {item.status !== "active" ? (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            style={{ color: "#FF0000" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-check"
                            style={{ color: "#5fb363" }}
                          />
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square btn"
                          className="editIcon"
                          style={{ marginRight: 10, color: "#e3ff0f" }}
                          onClick={() => {
                            modalTitle("Modify Customer");
                            modalFieldValue(index);
                            setId(item.id);
                            setModal(!modal);
                          }}
                        />
                        <FontAwesomeIcon
                          className="deleteIcon"
                          icon="fa-solid fa-trash"
                          style={{ color: "#f50000" }}
                          onClick={() => {
                            setId(item.id);
                            deleteSwal(index);
                          }}
                        />
                      </td>
                      <td>
                        <i
                          className="bi bi-arrow-right-circle detailIcon"
                          onClick={() => {
                            modalTitle("Jeweller Details");
                            modalFieldValue(index);
                            setModal(true);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      ) : (
        // Last 5 Customer Details
        <div className="CustomerList">
          <div className="cardHeader">
            <h2>Last 5 Customer</h2>
            <div
              className="CardHeaderRightView"
              style={{ justifyContent: "flex-end" }}
            >
              <Link to="/customer" className="btn">
                View All
              </Link>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Customer Name</td>
                <td>Card_id</td>
                <td>Balance</td>
                <td>Status</td>
                <td>Action</td>
                <td>Details</td>
              </tr>
            </thead>
            <tbody>
              {customerData.map((item, index) => {
                if (index < 5) {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.card_id}</td>
                      <td>{item.balance}</td>
                      <td>
                        {item.status !== "active" ? (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            style={{ color: "#FF0000" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-check"
                            style={{ color: "#5fb363" }}
                          />
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square btn"
                          className="editIcon"
                          style={{ marginRight: 10, color: "#e3ff0f" }}
                          onClick={() => {
                            modalTitle("Modify Customer");
                            modalFieldValue(index);
                            setId(item.id);
                            setModal(!modal);
                          }}
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          className="deleteIcon"
                          onClick={() => {
                            setId(item.id);
                            deleteSwal(index);
                          }}
                          style={{ color: "#f50000" }}
                        />
                      </td>
                      <td>
                        <i
                          className="bi bi-arrow-right-circle detailIcon"
                          onClick={() => {
                            modalTitle("Customer Details");
                            modalFieldValue(index);
                            setModal(true);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CustomerDetails;
