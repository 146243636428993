import { createSlice } from "@reduxjs/toolkit";

export const sidebarToggleSlice = createSlice({
  name: "sidebar",
  initialState: {
    sidebar: "",
    jeweller_id: "",
  },
  reducers: {
    sideBar: (state, action) => {
      state.sidebar = action.payload;
    },
    jeweller_id: (state, action) => {
      state.jeweller_id = action.payload;
    },
  },
});

// export default sidebarToggleSlice.reducer;
export const { sideBar, jeweller_id } = sidebarToggleSlice.actions;
