import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Common/Sidebar/Sidebar";
import Navbar from "../Common/Navbar/Navbar";
import Card from "../Common/Cards/Card";
import axios from "axios";
import { notify } from "../Common/FlashMessage/ToastContainer";
import { useNavigate } from "react-router-dom";
import TransactionDetails from "../Common/Transactions/TransactionDetails";
import { baseUrl } from "../API/url";

export default function Transaction() {
  const selector = useSelector((state) => state.sidebar);
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    // Api for spent and remain transaction amount count
    const transaction_count = async () => {
      const authToken = localStorage.getItem("auth_token")?.trim();
      if (!authToken) {
        console.error("Authentication token is missing.");
        // Handle the case where the authentication token is missing.
        navigate("/login");
        return;
      }
      try {
        const apiUrl = `${baseUrl}/transaction-count`;
        const requestData = {
          j_unique_id: localStorage.getItem("j_unique_id"),
        };
        const response = await axios.post(apiUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authToken.slice(1, -1)}`,
            j_unique_id:
              JSON.stringify(localStorage.getItem("user_role")) == "1"
                ? selector?.jeweller_id
                : localStorage.getItem("j_unique_id"),
          },
        });
        setTotal(response?.data?.data?.data?.spent);
        setActive(response?.data?.data?.data?.remain);
      } catch (error) {
        console.log("get transaction_count Api error ", error);
        notify("Some internal server error", "error");
        navigate("/login");
      }
    };

    (async () => await transaction_count())();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className={`main ${selector.sidebar}`}>
        <Navbar />
        <Card
          number={[total, active]}
          CardName={["Total Spent Value", "Active Remain Amount"]}
        />
        <TransactionDetails />
      </div>
    </div>
  );
}
